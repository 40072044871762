import * as Yup from "yup";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReusableModal from "../../../../../components/reusableModal";
import useApiPostMethodHook from "../../../../../features/customHooks/apiPostMethodHook";
import useApiPutMethodHook from "../../../../../features/customHooks/apiPutMethodHook";
import sweetSuccessAlert from "../../../../../widgets/alert/sweetAlert";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../languages";

const AddPaymentModal = ({
  onHide,
  modalShow,
  paymentMethodObj,
  toggleModalShow,
  updateAddRefresh,
}: any) => {

  const {t} =   useTranslation()
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState({
    message: "",
    alertBoxColor: "",
  });

  const initialValues = {
    name: paymentMethodObj.name,
    shortName : paymentMethodObj.shortName,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    shortName: Yup.string().required("Shortname is required"),
  });

  const {
    apiData,
    loading,
    error,
    success,
    apiStatus,
    showErrorAlert,
    setShowErrorAlert,
    postData,
  } = useApiPostMethodHook(`/payment-methods`, undefined, "hostel");

  const {
    apiData: getPutData,
    loading: getPutLoading,
    error: getPutError,
    success: getPutSuccess,
    apiStatus: getPutApiStaus,
    showErrorAlert : getPutShowErrorAlert,
    setShowErrorAlert : getSetShowErrorAlert,
    putData,
  } = useApiPutMethodHook(`/payment-methods/${paymentMethodObj.id}`, undefined, "hostel");

  useEffect(() => {
    if (apiData) {
      toggleModalShow(false);
      updateAddRefresh();
      sweetSuccessAlert({ text: t('hmd.paymentMethodAddedSuccess') });
    }

    if (getPutData && getPutSuccess) {
      toggleModalShow(false);
      updateAddRefresh();
      sweetSuccessAlert({ text: t('hmd.paymentMethodUpdatedSuccess') });
    }
  }, [apiData, getPutData]);

  const handleFormSubmit = useCallback(
    async (values: any, { setSubmitting, resetForm }: any) => {
        console.log(values)
      setSubmitting(true);
      if (paymentMethodObj.id === 0) {
        const payload = { ...values  };
        console.log(payload)
        await postData(payload);
        setSubmitting(false);
      } else {
        const payload = { ...values };
        await putData(payload);
        setSubmitting(false);
      }
    },
    [paymentMethodObj.id, toggleModalShow, updateAddRefresh]
  );

  const onModalHide = useCallback(() => {
    toggleModalShow(false);
    setShowErrorAlert(false);
    getSetShowErrorAlert(false)

  }, [toggleModalShow, setShowErrorAlert]);

  const formFieldsPacket = useMemo(() => {
    return [
      {
        star: "*",
        name: "name",
        type: "text",
        required: true,
        htmlfor: "name",
        labelText: t('common.name'),
        placeholder: t('hmd.enterPaymentName'),
        layout: "col",
      },

      {
        star: "*",
        name: "shortName",
        type: "text",
        required: true,
        htmlfor: "shortName",
        labelText: t('hmd.shortName'),
        placeholder: t('hmd.enterShortName'),
        layout: "col",
      },
      
    ];
  }, [ i18n.language]);

  return (
    <ReusableModal
      error={error}
      modalHeading={{
        ifCondition: t('hmd.addPaymentMethod'),
        elseCondition:  t('hmd.updatePaymentMethod'),
      }}
      conditionFlag={paymentMethodObj}
      modalShow={modalShow}
      onModalHide={onModalHide}
      initialValues={initialValues}
      modalContent={formFieldsPacket}
      showErrorAlert={showErrorAlert}
      validationSchema={validationSchema}
      setShowErrorAlert={setShowErrorAlert}
      handleFormSubmit={handleFormSubmit}
      getPutError= {getPutError}
      getPutShowErrorAlert = {getPutShowErrorAlert}
      getSetShowErrorAlert =  {getSetShowErrorAlert}
    />
  );
};

export default AddPaymentModal;

// // form field list === >>>
