import React from "react";
import Swal from "sweetalert2";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "sweetalert2/src/sweetalert2.scss";
import { Formik, Form, Field } from "formik";
import { TCommonProps } from "../../../../mailTempelateConfiguration/typescript";
import { RotatingLines } from "react-loader-spinner";
import CkEditor from "../../../../../widgets/editor/CKEditor";
import { postData } from "../../../../../adapters/coreservices";
import { Container, Row, Col, Alert } from "react-bootstrap";
import FieldLabel from "../../../../../widgets/formInputFields/labels";
import CustomButton from "../../../../../widgets/formInputFields/buttons";
import { LoadingButton } from "../../../../../widgets/formInputFields/buttons";
import FieldTypeTextarea from "../../../../../widgets/formInputFields/formTextareaField";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Browser: React.FC<TCommonProps> = (props) => {
  const { t } = useTranslation();
  const languageList = localStorage.getItem("languages")
    ? JSON.parse(localStorage.getItem("languages"))
    : "";
  //   handle form submit === >>
  const handleFormSubmit = (values: any, { setSubmitting, resetForm }: any) => {
    let newValue = [
      { configKey: values.config_1, configValue: values.subject },
      {
        configKey: values.config_0,
        configValue: values.description,
      },
    ];
    setSubmitting(true);
    postData(
      `config/save?languageCode=${props.commonProps.selectedValue}`,
      newValue
    )
      .then((res: any) => {
        if (res.data !== "" && res.status === 200) {
          setSubmitting(false);
          Swal.fire({
            timer: 3000,
            width: "25em",
            color: "#666",
            icon: "success",
            background: "#e7eef5",
            showConfirmButton: false,
            text: t("mailTemplateCongiguration.message"),
          });
          props.commonProps.toggleRefresh();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const permissions = useSelector(
    (state: any) => state.userAuthorities.permissions
  );
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );
  return (
    <React.Fragment>
      <Container fluid>
      {props.commonProps.getConfigApiStatus === "started" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          <RotatingLines
            visible={true}
            height="50"
            width="50"
            strokeWidth="5"
            strokeColor="#1B609D"
            animationDuration="0.2"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <Tab.Container defaultActiveKey={0}>
          <Row>
            <Col sm={3}>
              <Nav
                variant="pills"
                className="flex-column tabStep-indicator mailconfig-tabs"
              >
                {props.commonProps.tabTitles.length > 0 &&
                  props.commonProps.tabTitles.map(
                    (tabTitle: any, index: number) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          eventKey={index}
                          onClick={() =>
                            props.commonProps.setSelectedTab(tabTitle)
                          }
                        >
                          {tabTitle}
                        </Nav.Link>
                      </Nav.Item>
                    )
                  )}
              </Nav>
            </Col>
            <Col sm={9}>
              {props.commonProps.apiStatus === "finished" && (
                <Tab.Content>
                  {props.commonProps.tabTitles.map(
                    (tabTitle: any, index: number) => (
                      <Tab.Pane key={index} eventKey={index}>
                        <Formik
                          initialValues={{
                            subject: props.commonProps.initialSubject,
                            description:
                              props.commonProps.initialDescription,
                            ...props.commonProps.mailConfigData
                              .filter(
                                (item) => item.tabTitle === tabTitle
                              )
                              .reduce(
                                (
                                  acc: { [x: string]: any },
                                  item: { config: any },
                                  index: any
                                ) => {
                                  acc[`config_${index}`] = item.config;
                                  return acc;
                                },
                                {}
                              ),
                          }}
                          onSubmit={(values, action) => {
                            handleFormSubmit(values, action);
                          }}
                        >
                          {({ isSubmitting, handleChange }) => (
                            <Form>
                              <div className="d-flex flex-column">
                                {props.commonProps.mailConfigData
                                  .filter(
                                    (item: { tabTitle: any }) =>
                                      item.tabTitle === tabTitle
                                  )
                                  .sort(
                                    (
                                      a: { type: string },
                                      b: { type: string }
                                    ) => {
                                      if (
                                        a.type === "textfield" &&
                                        b.type === "textarea"
                                      ) {
                                        return -1;
                                      } else if (
                                        a.type === "textarea" &&
                                        b.type === "textfield"
                                      ) {
                                        return 1;
                                      }
                                      return 0;
                                    }
                                  )
                                  .map(
                                    (
                                      item: {
                                        config: string;
                                        availableVariables:
                                          | string
                                          | number
                                          | boolean
                                          | React.ReactElement<
                                              any,
                                              | string
                                              | React.JSXElementConstructor<any>
                                            >
                                          | Iterable<React.ReactNode>
                                          | React.ReactPortal
                                          | null
                                          | undefined;
                                        type: string;
                                        label: any;
                                      },
                                      index: React.Key | null | undefined
                                    ) => (
                                      <>
                                        {index === 0 && (
                                          <div className="d-flex justify-content-between align-items-center">
                                            <Alert
                                              variant="primary"
                                              className="mt-3"
                                            >
                                              <strong>Note: </strong>
                                              {item.availableVariables}
                                            </Alert>
                                            <div>
                                              <select
                                                className="form-select"
                                                value={
                                                  props.commonProps
                                                    .selectedValue
                                                }
                                                onChange={(e) =>
                                                  props.commonProps.languageSelectHandler(
                                                    e
                                                  )
                                                }
                                              >
                                                {Object.entries(
                                                  languageList
                                                ).map(
                                                  (
                                                    [key, value]: any,
                                                    index: number
                                                  ) => (
                                                    <option
                                                      key={index}
                                                      value={key}
                                                      data-name={value}
                                                    >
                                                      {value}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                        )}
                                        <React.Fragment key={index}>
                                          <Field
                                            type="text"
                                            name={`config_${index}`}
                                            hidden
                                          />
                                          {item.type === "textfield" && (
                                            <>
                                              <FieldLabel
                                                htmlfor="subject"
                                                labelText={item.label}
                                                className="mb-1 mt-2"
                                              />
                                              <Field
                                                type="text"
                                                name="subject"
                                                placeholder="Subject"
                                                className="mb-2"
                                              />
                                            </>
                                          )}
                                          {item.type === "textarea" && (
                                            <>
                                              <FieldLabel
                                                htmlfor="description"
                                                labelText={item.label}
                                                className="mb-1 mt-2"
                                              />
                                              {item.config ===
                                              "header_mail_body" ? (
                                                <FieldTypeTextarea
                                                  flag={true}
                                                  name="description"
                                                  onChange={handleChange}
                                                />
                                              ) : item.config ===
                                                "footer_mail_body" ? (
                                                <FieldTypeTextarea
                                                  flag={true}
                                                  name="description"
                                                  onChange={handleChange}
                                                />
                                              ) : (
                                                <CkEditor
                                                  name="description"
                                                  handleChange={
                                                    handleChange
                                                  }
                                                />
                                              )}
                                            </>
                                          )}
                                        </React.Fragment>
                                      </>
                                    )
                                  )}

                                {isSubmitting === false ? (
                                  <div className="modal-buttons my-3">
                                    <CustomButton
                                      type="submit"
                                      variant="primary"
                                      isSubmitting={isSubmitting}
                                      btnText={t("common.submit")}
                                    />
                                  </div>
                                ) : (
                                  <LoadingButton
                                    variant="primary"
                                    btnText={t("common.submitting")}
                                    className="modal-buttons"
                                  />
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </Tab.Pane>
                    )
                  )}
                </Tab.Content>
              )}
              {props.commonProps.loading !== false && (
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <h5>Loading...</h5>
                </div>
              )}
            </Col>
          </Row>
        </Tab.Container>
      )}
      </Container>
    </React.Fragment>
  );
};

export default Browser;
