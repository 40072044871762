import axios, {AxiosResponse} from 'axios';
import config from "../../utils/config";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import NewLoader from "../../widgets/loader";
import { useNavigate } from "react-router-dom";
import { getData } from "../../adapters/coreservices";
import UserContext from "../../features/context/user/user";
import React, { useContext, useEffect, useState } from "react";
import { globalAlertActions } from "../../store/slices/globalAlerts";
import { authenticatedUserActions } from "../../store/slices/userRoles";
import { createAxiosInstance } from "../../adapters/microservices/utils";
import { createCoreAxiosInstance } from "../../adapters/coreservices/utils";
import { globalUserInfoActions } from "../../store/slices/userInfo";
import { createHostelAxiosInstance } from "../../adapters/hostelservices/utils";
import { globalFilterActions } from '../../store/slices/globalFilters';

const AuthLogin = () => {
  const error = null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [authCode, setAuthCode] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const redirectUri = config.REDIRECT_URI;

  useEffect(() => {
     // Extract auth code from URL
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setAuthCode(params.code);
  }, []);

  useEffect(() => {
    // Fetch available languages
    axios
      .get(`${config.CORE_SERVICE_URL}/public/languages`)
      .then((result: any) => {
        if (result.data !== "" && result.status === 200) {
          localStorage.setItem("languages", JSON.stringify(result.data));
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   if (authCode !== "") {
  //     const VERIFY_URL = `${config.OAUTH2_URL}/api/verifycode?code=${authCode}&redirect_uri=${redirectUri}`;
  //     console.log('VERIFY_URL', VERIFY_URL);

  //     var requestOptions: any = {
  //       method: 'GET',
  //       redirect: 'follow'
  //     };

  //     axios.get(VERIFY_URL, requestOptions)
  //       .then((response) => {
  //         if (response.data === '') {
  //           console.log('verifycode , no token found', response);
  //           dispatch(globalAlertActions.globalAlert({ alertMsg: "User verification failed, please try again!", status: true }))
  //           navigate('/');
  //         } else {
  //           let result = response.data;
  //           setIsLoaded(true);
  //           if ('access_token' in result) {
  //             Object.entries(result).map(([key, value]: any) => {
  //               value = value.toString();
  //               sessionStorage.setItem(key, value);
  //               localStorage.setItem(key, value);  // added if app if reloaded for some url
  //             });

  //             createAxiosInstance(result.access_token);
  //             createCoreAxiosInstance(result.access_token);
  //             createHostelAxiosInstance(result.access_token);
  //             // config.WSTOKEN = config.ADMIN_MOODLE_TOKEN;
  //             config.WSTOKEN = result.access_token;
  //             config.OAUTH2_ACCESS_TOKEN = result.access_token;
  //             userCtx.setUserToken(config.WSTOKEN); // set WS TOKEN in context

  //             getData('/user-info', {}).then((res: any) => {
  //               if (res.data !== "" && res.status === 200) {
  //                 userCtx.setUserInfo(res.data) // set User-info data packet in context
  //                 let rolesWiseAuthorities: any = {};

  //                 const rolesKeys: any = Object.keys(res.data.roles);
  //                 let rolesData = [...res.data.roles[rolesKeys[rolesKeys.length - 1]]];

  //                 if (res.data.systemRoles !== undefined) {
  //                   Object.keys(res.data.systemRoles).forEach(key => {
  //                     rolesData.push(res.data.systemRoles[key]);
  //                   });
  //                   res.data.roles[rolesKeys[rolesKeys.length - 1]] = rolesData
  //                 }

  //                 Object.entries(res.data.authorities).forEach(([key, value]) => {
  //                   if (key !== "0") {
  //                     rolesWiseAuthorities[key] = value;
  //                   }
  //                 });

  //                 // added system role packet to all roles ===>>>
  //                 let allRolesWithSystemRole = { ...res.data.roles }; // Create a copy of roles
  //                 Object.entries(res.data.institutes).forEach(([key]) => {
  //                   if (key !== "0") {
  //                     // Check if the key exists in allRolesWithSystemRole and initialize if necessary
  //                     if (!allRolesWithSystemRole[key]) {
  //                       allRolesWithSystemRole[key] = [];
  //                     }

  //                     // Check if the system role is already present
  //                     if (Object.keys(res.data.systemRoles).length > 0) {
  //                       // Check if the system role is already present
  //                       const systemRoleExists = allRolesWithSystemRole[key].some((role: { id: any; }) => role.id === res.data.systemRoles["57"].id);
  //                       if (!systemRoleExists) {
  //                         // Push the system role into the respective institute's roles
  //                         allRolesWithSystemRole[key].push(res.data.systemRoles["57"]); // Add the system role
  //                       }
  //                     } else {
  //                       console.error("System role '57' is undefined.");
  //                     }
  //                   }
  //                 });

  //                 res.data !== "" && localStorage.setItem("allRoles", JSON.stringify(allRolesWithSystemRole));
  //                 // to get all institute 
  //                 const keys: any = Object.keys(res.data.institutes);
  //                 localStorage.setItem("allInstitute", JSON.stringify(keys));
  //                 localStorage.setItem("institute", keys[keys.length - 1]);
  //                 dispatch(globalFilterActions.currentInstitute(keys[keys.length - 1]))

  //                 rolesWiseAuthorities !== undefined && localStorage.setItem('allRolesAuthorities', JSON.stringify(rolesWiseAuthorities));
  //                 // set prifered language as selected value
  //                 localStorage.setItem('selectedLanguages', JSON.stringify(res.data.language))
  //                 //Authenticated User
  //                 res.data.authorities[0] !== undefined && dispatch(authenticatedUserActions.updateUserAuthorities(res.data.authorities[0]));
  //                 res.data.authorities[0] !== undefined && localStorage.setItem('authenticatedUser', JSON.stringify(res.data.authorities[0]));

  //                 // User Info packet 
  //                 res.data !== "" && dispatch(globalUserInfoActions.userInfo(res.data));
  //                 res.data !== "" && localStorage.setItem('userInfo', JSON.stringify({ userInfo: res.data }))
                  
  //                 // res.data.roles[rolesKeys[rolesKeys.length - 1]] !== undefined && localStorage.setItem('roles', JSON.stringify(rolesData));
                  
  //                 // Save systemRoles to local storage
  //                 res.data.systemRoles !== undefined && localStorage.setItem('systemRoles', JSON.stringify(res.data.systemRoles));

  //                 // Create a Set of institute keys for quick lookup
  //                 const instituteKeys = new Set(Object.keys(res.data.institutes));
  //                 Object.entries(res.data.roles).forEach(([roleKey, roleValues]: any) => { // Iterate over the entries of res.data.roles
  //                   // Check if the current roleKey exists in the institutes
  //                   if (instituteKeys.has(roleKey)) {
  //                     navigate('/home')
  //                   }
  //                 });
  //               }
  //             })

  //           } else {
  //             dispatch(globalAlertActions.globalAlert({ alertMsg: "User verification failed, no token found!", status: true }));
  //             navigate('/');
  //           }
  //         }
  //         // Handle the response here
  //         console.log('axios response success', response);
  //       })
  //       .catch(error => {
  //         // Handle errors here
  //         console.error('axios response eeor', error);
  //       });
  //   }
  // }, [authCode]);


  useEffect(() => {
    if (!authCode) return;

    const VERIFY_URL = `${config.OAUTH2_URL}/api/verifycode?code=${authCode}&redirect_uri=${redirectUri}`;

    axios
      .get(VERIFY_URL)
      .then((response: AxiosResponse) => {
        if (!response.data) {
          dispatch(globalAlertActions.globalAlert({ alertMsg: "User verification failed, please try again!", status: true }));
          navigate("/");
          return;
        }

        const result = response.data;
        setIsLoaded(true);

        if ("access_token" in result) {
          storeTokens(result);
          fetchUserInfo();
        } else {
          dispatch(globalAlertActions.globalAlert({ alertMsg: "User verification failed, no token found!", status: true }));
          navigate("/");
        }
      })
      .catch((error) => console.error("Error verifying user:", error));
  }, [authCode]);

  const storeTokens = (result: Record<string, string>) => {
    Object.entries(result).forEach(([key, value]) => {
      localStorage.setItem(key, value);
      sessionStorage.setItem(key, value);
    });

    createAxiosInstance(result.access_token);
    createCoreAxiosInstance(result.access_token);
    createHostelAxiosInstance(result.access_token);

    config.WSTOKEN = result.access_token;
    config.OAUTH2_ACCESS_TOKEN = result.access_token;
    userCtx.setUserToken(config.WSTOKEN);
  };

  const fetchUserInfo = async () => {
    try {
      const res = await getData("/user-info", {});
      if (!res.data || res.status !== 200) return;

      userCtx.setUserInfo(res.data);

      let rolesKeys = Object.keys(res.data.roles);
      let rolesData = [...res.data.roles[rolesKeys[rolesKeys.length - 1]]];

      if (res.data.systemRoles) {
        Object.values(res.data.systemRoles).forEach((role) => rolesData.push(role));
        res.data.roles[rolesKeys[rolesKeys.length - 1]] = rolesData;
      }

      let rolesWiseAuthorities: Record<string, unknown> = {};
      Object.entries(res.data.authorities).forEach(([key, value]) => {
        if (key !== "0") rolesWiseAuthorities[key] = value;
      });

      // Assign system role "57" to institutes
      const allRolesWithSystemRole = { ...res.data.roles };
      Object.keys(res.data.institutes).forEach((key) => {
        if (!allRolesWithSystemRole[key]) allRolesWithSystemRole[key] = [];
        if (res.data.systemRoles && res.data.systemRoles["57"] && !allRolesWithSystemRole[key].some((r: { id: any; }) => r.id === res.data.systemRoles["57"].id)) {
          allRolesWithSystemRole[key].push(res.data.systemRoles["57"]);
        }
      });

      localStorage.setItem("allRoles", JSON.stringify(allRolesWithSystemRole));
      localStorage.setItem("allInstitute", JSON.stringify(Object.keys(res.data.institutes)));
      localStorage.setItem("institute", Object.keys(res.data.institutes).pop()!);
      dispatch(globalFilterActions.currentInstitute(Object.keys(res.data.institutes).pop()!));

      if (rolesWiseAuthorities) localStorage.setItem("allRolesAuthorities", JSON.stringify(rolesWiseAuthorities));
      localStorage.setItem("selectedLanguages", JSON.stringify(res.data.language));

      if (res.data.authorities[0]) {
        dispatch(authenticatedUserActions.updateUserAuthorities(res.data.authorities[0]));
        localStorage.setItem("authenticatedUser", JSON.stringify(res.data.authorities[0]));
      }

      dispatch(globalUserInfoActions.userInfo(res.data));
      localStorage.setItem("userInfo", JSON.stringify({ userInfo: res.data }));
      localStorage.setItem("roles", JSON.stringify(rolesData));

      if (res.data.systemRoles) localStorage.setItem("systemRoles", JSON.stringify(res.data.systemRoles));

      const instituteRoles = Object.keys(res.data.institutes).map(
        (institute) => res.data.roles[institute]
      );
      
      if (instituteRoles.some((role) => role === "hosteladmin" || role === "hostelwarden")) {
        navigate("/siteadmin/hostelmanagement");
      } else {
        navigate("/home");
      }

    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const loaderStyle = {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <React.Fragment>
      <Container style={loaderStyle}>
        <div>
          {(error && <div>{error}</div>) ||
            (!isLoaded && (
              <div>
                <NewLoader />
              </div>
            ))}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default AuthLogin;
