import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
// import { TProps } from "../typescript";
import { TProps } from "../../../mailTempelateConfiguration/typescript";
import { isMobile, isDesktop } from "react-device-detect";

const View: React.FC<TProps> = (props) => {
  const commonProps = {
    loading: props.loading,
    apiStatus: props.apiStatus,
    tabTitles: props.tabTitles,
    selectedValue: props.selectedValue,
    mailConfigData: props.mailConfigData,
    initialSubject: props.initialSubject,
    getConfigApiStatus: props.getConfigApiStatus,
    initialDescription: props.initialDescription,
    toggleRefresh: props.toggleRefresh,
    setSelectedTab: props.setSelectedTab,
    languageSelectHandler: props.languageSelectHandler
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;
