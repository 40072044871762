import { Container } from "react-bootstrap";
// import HeaderTabs from "../../../../../../headerTabs";
import PageTitle from "../../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";
import BottomWave from "../../../../../../../assets/images/background/bg-bottom.svg";
import Filter from "../../filter";
import BuildPagination from "../../../../../../../widgets/pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PaymentMethodTable from "../../table";
import AddPaymentModal from "../../form";

type Props = {
  commonProps: {
    error: any;
    onHide: any;
    loading: any;
    paymentMethodObj: any;
    paymentMethodsData: any;
    modalShow: any;
    apiStatus: any;
    permissions: any;
    totalPages: any;
    filterUpdate: any;
    refreshToggle: any;
    showErrorAlert: any;
    toggleModalShow: any;
    editHandlerById: any;
    newPageRequest: any;
    openAddMealModal: any;
    setShowErrorAlert: any;
    updateInputFilters: any;
    refreshOnDeleteToggle: any;
  };
};

const Browser = (props: Props) => {
  const { t } = useTranslation();
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );
  return (
    <>      
      <div className="contentarea-wrapper mb-5">
        <BreadcrumbComponent
          routes={
            !["hosteladmin", "hostelwarden"].includes(currentUserRole.shortName)
              ? [
                  { name: t("hmd.mngHostel"), path: "/managehostel" },
                  { name: t("common.siteAdmin"), path: "/siteadmin" },
                  { name: t("hmd.hosteMng"), path: "/siteadmin/hostelmanagement" },
                  { name: t('hmd.invoices&Payments'), path: "/siteadmin/hostelmanagement/allusersinvoice/active/pending" },
                  { name: t('hmd.paymentMethod'), path: '' },
                ]
              : [
                  { name: t("hmd.hosteMng"), path: "/siteadmin/hostelmanagement" },
                  { name: t("hmd.mngHostel"), path: "/managehostel" },
                  { name: t('hmd.invoices&Payments'), path: "/siteadmin/hostelmanagement/allusersinvoice/active/pending" },
                  { name: t('hmd.paymentMethod'), path: '' },
                ]
          }
        />
        <Container fluid className="mt-3">
          <PageTitle
            pageTitle={t('hmd.paymentMethod')}
            gobacklink="/siteadmin/hostelmanagement/allusersinvoice/active/pending"
          />
          <Filter
            apiStatus={props.commonProps.apiStatus}
            permissions={props.commonProps.permissions}
            toggleModalShow={props.commonProps.toggleModalShow}
            openAddMealModal={props.commonProps.openAddMealModal}
            updateInputFilters={props.commonProps.updateInputFilters}
          />
          <PaymentMethodTable
            error={props.commonProps.error}
            loading={props.commonProps.loading}
            paymentMethodsData={props.commonProps.paymentMethodsData}
            apiStatus={props.commonProps.apiStatus}
            permissions={props.commonProps.permissions}
            filterUpdate={props.commonProps.filterUpdate}
            showErrorAlert={props.commonProps.showErrorAlert}
            editHandlerById={props.commonProps.editHandlerById}
            toggleModalShow={props.commonProps.toggleModalShow}
            refreshdata={props.commonProps.refreshOnDeleteToggle}
            setShowErrorAlert={props.commonProps.setShowErrorAlert}
          />

          <AddPaymentModal
            onHide={props.commonProps.onHide}
            paymentMethodObj={props.commonProps.paymentMethodObj}
            modalShow={props.commonProps.modalShow}
            updateAddRefresh={props.commonProps.refreshToggle}
            toggleModalShow={props.commonProps.toggleModalShow}
          />

          <BuildPagination
            totalpages={props.commonProps.totalPages}
            getrequestedpage={props.commonProps.newPageRequest}
            activepage={props.commonProps.filterUpdate.pageNumber}
          />
        </Container>
      </div>
      <div className="bottom-bg">
        <img src={BottomWave} alt="bottom wave" />
      </div>
    </>
  );
};

export default Browser;
