import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { capitalizeFirstWords } from "../../../../globals/titleCapitalize/capitalizeFirstWords";
import {
  dateConverterToDYM,
  formatDateToDDMMYYYY,
} from "../../../../lib/timestampConverter";
import RouterLadyLoader from "../../../../globals/globalLazyLoader/routerLadyLoader";
import { MdHelpOutline } from "react-icons/md";
import invoiceDownload from "../../../../globals/pdfDownload";
import { FaDownload } from "react-icons/fa6";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  OverlayTrigger,
  Tooltip as BsTooltip,
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";

type Props = {
  studentBillData: any;
  toggleModalShow: any;
  waiveToggleModalShow: any;
  loading: any;
};
const UserInvoiceDetails = (props: Props) => {
  const { method } = useParams();
  const { t } = useTranslation();

  const studentBillData = props.studentBillData || {};
  const {
    fullName = "",
    email = "",
    hostelName = "",
    bedNumber = "",
    roomNumber = "",
    validFrom = "",
    validTo = "",
    dueDate = "",
    billstatus = "",
    amount,
    payments = [],
    serviceCharge = "",
    discount = "",
    lateFeeCharge = "",
    amountDue = "",
    totalPaidAmount = "",
    waiveOffAmount = "",
    payableAmountAfterWaiveOff = "",
    lateFeeAmount = "",
    totalServiceChargeAfterDiscount = "",
    programName = "",
    programCode = "",
    waiveNote = "",
    discountAmount = "",
    userBillId = "",
  } = studentBillData;
  console.log(studentBillData);

  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  return (
    <>
      {props.loading ? (
        <RouterLadyLoader status={true} />
      ) : (
        <>
          <Container fluid className="mt-4">
            <Row>
              <Col lg={7}>
                <h5>{t("hmd.studentDetails")}</h5>
                <ul className="colon-list mb-4">
                  <li>
                    <b>{t("hmd.fullName")}</b>
                    {fullName ? capitalizeFirstWords(fullName) : null}
                  </li>
                  <li>
                    <b>{t("common.email")}</b>
                    {email ? capitalizeFirstWords(email) : null}
                  </li>
                  <li>
                    <b>{t("common.prgName")}</b>
                    {programName ? capitalizeFirstWords(programName) : null}
                  </li>
                  <li>
                    <b>{t("common.prgCode")}</b>
                    {programCode ? capitalizeFirstWords(programCode) : null}
                  </li>
                  <li>
                    <b>{t("hmd.hostelName")}</b>
                    {hostelName ? capitalizeFirstWords(hostelName) : null}
                  </li>
                  <li>
                    <b>{t("hmd.roomNo")}</b>
                    <span className="bluColor">
                      {roomNumber ? capitalizeFirstWords(roomNumber) : null}
                    </span>
                  </li>
                  <li>
                    <b>{t("hmd.bedNo")}</b>
                    <span className="bluColor">
                      {bedNumber ? capitalizeFirstWords(bedNumber) : null}
                    </span>
                  </li>
                </ul>

                {payments &&
                  payments.length > 0 &&(
                  <>
                    <h5>{t("hmd.paymentSummary")}</h5>
                    <div className="table-responsive mb-2">
                      <Table borderless striped hover>
                        <thead>
                          <tr>
                            <th>{t("common.sNo")}</th>
                            <th>{t("hmd.createdBy")}</th>
                            <th>{t("hmd.paidBy")}</th>
                            <th>{t("hmd.transactionStatus")}</th>
                            <th>{t("common.note")}</th>
                            {/* <th>{t("hmd.transactionId")}</th> */}
                            <th>{t("hmd.transactionNumber")}</th>
                            <th>{t("common.date")}</th>
                            <th>{t("hmd.amount")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payments.map((payment: any, index: number) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{payment.createdBy}</td>
                                <td>{payment?.paymentMethod?.name || "N/A"}</td>
                                <td><div className={payment?.transaction?.transactionStatusType !=="success"? "statusBadges pending": "statusBadges active" }>{payment?.transaction?.transactionStatusType || "N/A"}</div></td>
                                <td>{payment?.transaction?.transactionNote || "N/A"}</td>
                                {/* <td>{payment?.transaction?.id || "N/A"}</td> */}
                                <td>{payment?.transaction?.transactionNumber || "N/A"}</td>
                                <td>{formatDateToDDMMYYYY(payment.paymentDate) || "N/A"}</td>
                                <td>
                                  {payment?.transaction?.amount
                                    ? `${payment.transaction.amount} INR`
                                    : "N/A"}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}
                <Button
                  onClick={() => invoiceDownload(userBillId, currentInstitute)}
                  className="mt-2"
                >
                  <FaDownload />{" "}
                  {billstatus === "paid" ? t("hmd.paymentReceipt") : t("hmd.invoice")}
                </Button>
              </Col>
              <Col lg={5}>
                <div className="invoiceDetails">
                  <h5>{t("hmd.invoiceDetails")}</h5>
                  <ul className="colon-list">
                    <li>
                      <b>{t("hmd.invoiceStatus")}</b>
                      <span
                        className={`statusBadges ${billstatus === "paid" ? "active" : billstatus
                          }`}
                      >
                        {capitalizeFirstWords(billstatus)}
                      </span>
                    </li>
                    <li>
                      <b>{t("hmd.dueDate")}</b>
                      <span className="bluColor">
                        {dueDate ? formatDateToDDMMYYYY(dueDate) : null}
                      </span>
                    </li>
                    <li>
                      <b>{t("hmd.billingPeriod")}</b>
                      <span className="bluColor">
                        {validFrom && validTo
                          ? `${formatDateToDDMMYYYY(validFrom)} ${t(
                            "hmd.to"
                          )} ${formatDateToDDMMYYYY(validTo)}`
                          : null}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="chargeSummary mt-4 mb-4">
                  <h5>{t("hmd.chargesSummary")}</h5>
                  <ul className="colon-list">
                    <li>
                      <strong>{t("common.description")}</strong>
                    </li>
                    {serviceCharge ? (
                      <li>
                        <b>{t("hmd.serviceCharge")}</b> {serviceCharge}{" "}
                        {t("hmd.inr")}
                      </li>
                    ) : null}
                    {discount && discount > 0 ? (
                      <li>
                        <b>
                          {t("hmd.discount")} {discount}%
                        </b>{" "}
                        - {discountAmount} {t("hmd.inr")}
                      </li>
                    ) : null}
                    {totalServiceChargeAfterDiscount &&
                      discount &&
                      discount > 0 ? (
                      <li>
                        <b>{t("hmd.totalServiceCharge")} </b>{" "}
                        {totalServiceChargeAfterDiscount} {t("hmd.inr")}
                      </li>
                    ) : null}
                    {lateFeeAmount && lateFeeCharge > 0 ? (
                      <li>
                        <b>
                          {t("hmd.lateFeeCharge")} ({lateFeeAmount}{" "}
                          {t("hmd.inr")} {t("hmd.perDay")})
                        </b>{" "}
                        {lateFeeCharge} {t("hmd.inr")}
                      </li>
                    ) : null}
                    {amount ? (
                      <li>
                        <b>{t("hmd.totalAmount")}</b> {amount} {t("hmd.inr")}
                      </li>
                    ) : null}
                    {totalPaidAmount ? (
                      <li>
                        <b>{t("hmd.totalPaid")}</b> {totalPaidAmount}{" "}
                        {t("hmd.inr")}
                      </li>
                    ) : null}
                    {waiveOffAmount && waiveOffAmount > 0 ? (
                      <li>
                        <b>
                          {t("hmd.waiveOffAmount")}
                          {
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <BsTooltip id="button-tooltip-2">
                                  {waiveNote}
                                </BsTooltip>
                              }
                            >
                              <Button variant="link" className="p-0">
                                <MdHelpOutline
                                  fontSize={20}
                                  className="align-top"
                                />
                              </Button>
                            </OverlayTrigger>
                          }
                        </b>
                        {waiveOffAmount} {t("hmd.inr")}
                      </li>
                    ) : null}
                    {!waiveOffAmount ? (
                      amountDue && amountDue > 0 ? (
                        <li>
                          <b>{t("hmd.dueAmount")}</b> {amountDue} {t("hmd.inr")}
                        </li>
                      ) : null
                    ) : payableAmountAfterWaiveOff ? (
                      <li>
                        <b>{t("hmd.amountPayableAfterWaifoff")}</b>{" "}
                        {payableAmountAfterWaiveOff} {t("hmd.inr")}
                      </li>
                    ) : null}
                  </ul>

                  {billstatus !== "paid" && method === "pay" && (
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => props.toggleModalShow(true)}
                      >
                        {t("hmd.addPayment")}
                      </Button>
                    </div>
                  )}

                  {/* <h5>Remaning Amount </h5> */}

                  {billstatus !== "paid" &&
                    method === "waive" &&
                    !waiveOffAmount && (
                      <div>
                        <Button
                          variant="primary"
                          onClick={() => props.waiveToggleModalShow(true)}
                        >
                          {t("hmd.waiveOff")}
                        </Button>
                      </div>
                    )}
                  {billstatus !== "paid" &&
                    method === "waive" &&
                    waiveOffAmount && (
                      <div>
                        <Button
                          variant="primary"
                          onClick={() => props.waiveToggleModalShow(true)}
                        >
                          {t("hmd.editWaiveOff")}
                        </Button>
                      </div>
                    )}
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default UserInvoiceDetails;
