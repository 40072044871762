import View from "./view";
import React, { useEffect, useState } from "react";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";
import { useSelector } from "react-redux";

type Props = {};

const PaymentMethods = (props: Props) => {

  const dummyData = {
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  };
  const [modalShow, setModalShow] = useState(false);
  const [refreshData, setRefreshData] = useState(true);
  const [refreshOnDelete, setRefreshOnDelete] = useState<boolean>(false);
  const [paymentMethodsData, setPaymentMethodsData] = useState(dummyData);
  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: 10,
  });
  const initialValue = {
    id: 0,
    name: "",
    shortName : ""
  };

  const [paymentMethodObj, setPaymentMethodObj] = useState<any>(initialValue);

  const permissions = useSelector(
    (state: any) => state.userAuthorities.permissions
  );

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate({ ...filterUpdate, pageNumber: pageRequest });
  };

  /* < --- call api to get Payment Methods --- > */
  const {
    apiData,
    loading,
    error,
    apiStatus,
    showErrorAlert,
    setShowErrorAlert,
    refetch,
  } = useApiGetMethodHook<any>(`/payment-methods`, filterUpdate, "hostel");
  useEffect(() => {
    setPaymentMethodsData(dummyData);
    refetch();
  }, [refreshData, filterUpdate, refreshOnDelete]);

  // Update mealData when apiData changes
  useEffect(() => {
    if (apiData) {
      setPaymentMethodsData({
        items:
          apiData.items?.sort(
            (item1: any, item2: any) => item1.id - item2.id
          ) || [],
        pager: apiData.pager || { totalElements: 0, totalPages: 0 },
      });
    }
  }, [apiData]);

  // handle modal hide & show functionality
  const toggleModalShow = (status: boolean) => {
    setModalShow(status);
  };

  const refreshToggle = () => {
    setRefreshData(!refreshData);
  };

  const refreshOnDeleteToggle = (value: boolean) => {
    setRefreshOnDelete(value);
  };

  // handle to Edit preference modal
  const editHandlerById = (obj: any) => {
    console.log(obj)
    setPaymentMethodObj(obj);
  };

  const updateInputFilters = (inputvalues: any) => {
    // if (inputvalues.reset !== undefined) {
    //   let updatedState = { ...filterUpdate, pageNumber: 0 };
    //   delete updatedState.name;
    //   delete updatedState.userPreferences;
    //   setFilterUpdate(updatedState);
    //   return false;
    // }
    // if (inputvalues !== "") {
    //   setFilterUpdate({
    //     ...filterUpdate,
    //     pageNumber: 0,
    //     name: inputvalues.name,
    //     userPreferences: inputvalues.userPreferences,
    //   });
    // } else {
    //   let updatedState = { ...filterUpdate, pageNumber: 0 };
    //   updatedState.name = inputvalues.name;
    //   updatedState.userPreferences = inputvalues.userPreferences;
    //   setFilterUpdate(updatedState);
    // }
  };

  // handle to open Add Facility modal === >>>

  const openAddMealModal = () => {
    toggleModalShow(true);
    setPaymentMethodObj(initialValue);
  };

  return (
    <View
      error={error}
      paymentMethodObj={paymentMethodObj}
      loading={loading}
      modalShow={modalShow}
      apiStatus={apiStatus}
      paymentMethodsData={paymentMethodsData.items}
      permissions={permissions}
      filterUpdate={filterUpdate}
      showErrorAlert={showErrorAlert}
      setShowErrorAlert={setShowErrorAlert}
      totalPages={paymentMethodsData.pager.totalPages}
      refreshToggle={refreshToggle}
      newPageRequest={newPageRequest}
      toggleModalShow={toggleModalShow}
      editHandlerById={editHandlerById}
      openAddMealModal={openAddMealModal}
      onHide={() => toggleModalShow(false)}
      updateInputFilters={updateInputFilters}
      refreshOnDeleteToggle={refreshOnDeleteToggle}
    />
  );
};

export default PaymentMethods;
