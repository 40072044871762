import React from 'react'
import { Outlet, Route } from 'react-router-dom'
import UsersInvoiceDeatil from '../../../pages/hostelManagement/offlinePayment/admin'
import UserInvoice from '../../../pages/hostelManagement/offlinePayment/UserInvoice'
import UserWallet from '../../../pages/hostelManagement/offlinePayment/userWallet'
import Paymentmethod from '../../../pages/hostelManagement/offlinePayment/admin/PaymentMethod'
import PaymentDetails from '../../../pages/hostelManagement/offlinePayment/UserInvoice/paymentDetails'
// import AssignRoomNew from '../../../pages/hostelManagement/roomAllocation/assignRoom'

const HostelManagement = React.lazy(() => import('../../../pages/hostelManagement'))
const ManageRooms = React.lazy(() => import('../../../pages/hostelManagement/manageRooms'))
const Meals = React.lazy(()=> import('../../../pages/hostelManagement/manageFacility/meal'))
const ManageHostel = React.lazy(() => import('../../../pages/hostelManagement/manageHostel'))
const ManageFacility = React.lazy(() => import('../../../pages/hostelManagement/manageFacility'))
const RoomAllocation = React.lazy(() => import('../../../pages/hostelManagement/roomAllocation'))
const BookingRequest = React.lazy(() => import('../../../pages/hostelManagement/bookingRequest'))
const ServiceRequest = React.lazy(() => import('../../../pages/hostelManagement/serviceRequest'))
const VisitorRequest = React.lazy(() => import('../../../pages/hostelManagement/visitorRequest'))
const MessMenu = React.lazy(() => import('../../../pages/hostelManagement/manageFacility/messMenu'))
const HostelContactList = React.lazy(()=> import('../../../pages/hostelManagement/hostelContactList'))
const AssignRoom = React.lazy(()=> import('../../../pages/hostelManagement/roomAllocation/assignRoom'))
const RequestType = React.lazy(()=> import('../../../pages/hostelManagement/serviceRequest/requesType'))
const DiscountFacility = React.lazy(()=> import('../../../pages/hostelManagement/manageFacility/discount'))
const Preference = React.lazy(()=> import('../../../pages/hostelManagement/manageFacility/preferences'))
const AddFacilitiesHostal = React.lazy(() => import('../../../pages/hostelManagement/manageHostel/addFacilities'))
const PreferenceType = React.lazy(()=> import('../../../pages/hostelManagement/manageFacility/preferences/preferenceType'))

const HostelManagementRoute = () => (
    <Route path='hostelmanagement' element={<Outlet />} >
      <Route index element={<HostelManagement />} />,

      {/* manage hostel routes */}
      <Route key='managehostel' path='managehostel' element={<ManageHostel />} />,
      <Route key='addfacilities' path='managehostel/addfacilities/:id' element={<AddFacilitiesHostal />} />,
      <Route key='hostelcontact' path='managehostel/hostelcontact' element={<HostelContactList/>}/>,
      <Route key='managerooms' path='managehostel/managerooms/:selectedHostelId?/:name?' element= {<ManageRooms/>} />,

      {/* booking request routes */}
      <Route key='bookingrequest' path='bookingrequest/:bookingReqStatus?/:eventId?' element={<Outlet />} >
      <Route index element={<BookingRequest />} />
        <Route key='assignroom' path='assignroom/:id?' element={<AssignRoom />} />,
        <Route key='roomallocation' path='roomallocation' element={<Outlet />} >
          <Route index element={<RoomAllocation />} />
          <Route key='assignroom' path='assignroom/:id?' element={<AssignRoom />} />,
        </Route>,
      </Route>,
      
      {/* visitor request routes */}
      <Route key='visitorrequest' path='visitorrequest/:pending?/:eventId?' element={<VisitorRequest />} />,
      
      {/* facilities routes */}
      <Route key='managefacility' path='managefacility' element={<Outlet />} >
        <Route index element={<ManageFacility />} />
        <Route key='meals' path='meals' element={<Meals />} />,
        <Route key='messmenu' path='messmenu' element={<MessMenu />} />,
        <Route key='preferences' path='preferences' element={<Preference />} />,
        <Route key='preferencetype' path='preferences/preferencetype' element={<PreferenceType />} />,
        <Route key='discountfacility' path='discountfacility' element={<DiscountFacility />} />,
      </Route>,
      
      {/* service request routes */}
      <Route key='servicerequest' path='servicerequest/:selectedReqStatus?/:eventId?'  element={<ServiceRequest />} />,
      <Route key='requesttype' path='servicerequest/:selectedReqStatus?/:eventId?/requesttype' element={<RequestType />} />,

      {/* offline payment routs */}
      <Route key='allusersinvoice' path='allusersinvoice/:allocationStatus/:paymentStatus' element={<Outlet/>} >
        <Route index element={<UsersInvoiceDeatil/>} />
        <Route key='paymentmethod' path='paymentmethod' element={<Paymentmethod/>}/>
        <Route key='userinvoice' path='userinvoice/:id/:method' element={<UserInvoice />} />
        {/* <Route key='userinvoice' path='userinvoice/:id/:fullname/:roomId/:bedId' element={<Outlet />} >
          <Route index element={<UserInvoice />} />
          <Route key='userwallet' path='userwallet/:id/:fullname' element={<UserWallet/>}/>,
          <Route key='PaymentDetails' path='paymentdetails/:fullname' element={<PaymentDetails/>}/>,
        </Route>, */}
      </Route>,  
    
      {/* manage rooms routes */}
      <Route key='managerooms' path='managerooms' element= {<ManageRooms/>} />,
    </Route>
    // <Route key='assignroomnew' path='/assignroomnew' element={<AssignRoomNew />} />,
)

export default HostelManagementRoute

