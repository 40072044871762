import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../../../../widgets/pageTitle";
import BuildPagination from "../../../../../../widgets/pagination";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import ProgramEnrollFilter from "../../programEnrollFilter";
import ProgramEnrollTable from "../../programEnrollTable";
// import HeaderTabs from "../../../../../headerTabs";
import BottomWave from "../../../../../../assets/images/background/bg-bottom.svg";

type Props = {
  commonProps: {
    apiStatus: any;
    totalpages: any;
    filterUpdate: any;
    setFilterUpdate:any;
    enrollmentData: any;
    newPageRequest: any;
    updateDepartment: any;
    currentInstitute: any;
    updateinputfilters: any;
  };
};

const Browser = (props: Props) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>      
      <div className="contentarea-wrapper mb-5">
        <BreadcrumbComponent
          routes={[
            { name: t("common.home"), path: "/home" },
            { name: t('common.siteAdmin'), path: "/siteadmin" },
            { name: t('naac.coPoMgmt'), path: "" },
          ]}
        />
        <Container fluid className="mt-3">
        <PageTitle pageTitle={t('naac.coPoMgmt')} gobacklink="/siteadmin" />
          <ProgramEnrollFilter
            apiStatus={props.commonProps.apiStatus}
            updateDepartment={props.commonProps.updateDepartment}
            updateinputfilters={props.commonProps.updateinputfilters}
            currentInstitute={props.commonProps.currentInstitute}
          />
          <ProgramEnrollTable
            apiStatus={props.commonProps.apiStatus}
            enrollmentData={props.commonProps.enrollmentData}
            setFilterUpdate={props.commonProps.setFilterUpdate}
            filterUpdate={props.commonProps.filterUpdate}
          />

          <BuildPagination
            totalpages={props.commonProps.totalpages}
            getrequestedpage={props.commonProps.newPageRequest}
            activepage={props.commonProps.filterUpdate.pageNumber}
          />
        </Container>
      </div>
      <div className="bottom-bg">
        <img src={BottomWave} alt="bottom wave" />
      </div>
    </React.Fragment>
  );
};

export default Browser;
