import React from 'react'
import { Outlet, Route } from 'react-router-dom'

const Permission = React.lazy(() => import("../../../pages/siteAdminstration/roles/permissions"))
const UserManagement = React.lazy(() => import("../../../pages/siteAdminstration/userManagement"))
const AssignRoles = React.lazy(() => import("../../../pages/siteAdminstration/roles/assignRoles"))
const ManageRoles = React.lazy(() => import("../../../pages/siteAdminstration/roles/manageRoles"))
const ManageAuthorities = React.lazy(() => import("../../../pages/siteAdminstration/roles/authorities"))
const GuestUsers = React.lazy(() => import("../../../pages/siteAdminstration/userManagement/guestUsers"))
const SuperAdmin = React.lazy(() => import("../../../pages/siteAdminstration/userManagement/superAdmin"))
const ManageCaste = React.lazy(() => import("../../../pages/siteAdminstration/userManagement/manageCaste"))
const ViewUserProfile = React.lazy(() => import("../../../pages/siteAdminstration/userManagement/profile"))
const EditUserProfile = React.lazy(()=> import("../../../pages/siteAdminstration/userManagement/profile/forms/editProfile/index"))

const UserManagementRoute = () => (
  <Route path="usermanagement" element={<Outlet />}>
    <Route index element={<UserManagement />} />
    <Route path="superadmin" element={<SuperAdmin />} />
    <Route path="managecaste" element={<ManageCaste />} />
    <Route path="guestusers" element={<GuestUsers />} />
    <Route path="manageroles" element={<ManageRoles />} />
    <Route path="assignroles" element={<AssignRoles />} />
    <Route path="assignroles/:userId" element={<AssignRoles />} />
    <Route path="userprofile/:userid" element={<ViewUserProfile />} />
    <Route path="userprofile/:userid/edituserprofile/:userid" element={<EditUserProfile />} />
    <Route path="manageauthorities" element={<ManageAuthorities />} />
    <Route path="manageroles/rolepermissions/:roleId/:roleName" element={<Permission />} />
  </Route>
);

export default UserManagementRoute