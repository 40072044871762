import axios from "axios";
import debounce from "lodash/debounce";
import config from "../../utils/config";
import { useState, useEffect, useCallback, SetStateAction } from "react";
import { Field } from "formik";
import { InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";

interface SearchResult {
  id: number;
  name: string;
  userEmail: string;
}

const SearchComponent = ({
  endPoint,
  name,
  handleChange,
  setFieldValue,
  value,
  disabled,
}: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem("token") || "";
  const [showResults, setShowResults] = useState(false);

  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: 100,
  });

  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (query: string) => {
      if (!query) return;
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${config.CORE_SERVICE_URL}/${currentInstitute}/${endPoint}`,
          {
            params: {
              pageNumber: filterUpdate.pageNumber,
              pageSize: filterUpdate.pageSize,
              emailStartWith: query,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setResults(response.data.items || []);
      } catch (err: any) {
        setError(err.response?.data?.message || "Error fetching results");
      } finally {
        setLoading(false);
      }
    }, 300),
    [endPoint, filterUpdate, token, currentInstitute] // Dependencies
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  return (
    <div>
      <InputGroup>
        <InputGroup.Text>
          {loading ? (
            <b style={{ color: "gray" }}>...</b>
          ) : (
            <FaSearch color="gray" />
          )}
        </InputGroup.Text>
        {/* <Field
          type="text"
          name={name}
          // value={searchTerm}
          value={value}
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            handleChange(e);
            setSearchTerm(e.target.value);
            setShowResults(true);
          }}
          onClick={() => {
            if (searchTerm) {
              setShowResults(true);
            }
          }}
          placeholder="Type here ..."
          className="form-control"
          disabled={disabled}
        /> */}
        <Field
          type="text"
          name={name}
          value={searchTerm || value} // Ensure the selected email is reflected
          onChange={(e: { target: { value: SetStateAction<string>; }; }) => {
            setSearchTerm(e.target.value);
            handleChange(e); // Update Formik state
            setShowResults(true);
          }}
          onClick={() => {
            if (searchTerm) {
              setShowResults(true);
            }
          }}
          placeholder="Type here ..."
          className="form-control"
          disabled={disabled}
        />
      </InputGroup>

      {searchTerm &&
        !loading &&
        (error ? (
          <p className="error-message">
            <i className="fa fa-circle-exclamation"></i> {error}
          </p>
        ) : (
          results.length === 0 && (
            <p className="error-message">
              <i className="fa fa-circle-exclamation"></i> No matching email
              found.
            </p>
          )
        ))}

      {/* Display the filtered results below the input field */}
      {showResults && searchTerm && results.length > 0 && (
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            maxHeight: "200px",
            overflowY: "auto",
            marginTop: "5px",
          }}
        >
          {results.map((option: SearchResult) => (
            // <div
            //   key={option.id}
            //   onClick={() => {
            //     setSearchTerm(option.userEmail);
            //     setFieldValue("userEmail", option.userEmail); // Ensure the selected value is set
            //     setShowResults(false); // Hide the list after selecting an option
            //   }}
            <div
            key={option.id}
            onClick={() => {
              setSearchTerm(option.userEmail); // Update input field
              setFieldValue(name, option.userEmail); // Update Formik state
              setShowResults(false);
            }}
              style={{
                padding: "8px 12px",
                cursor: "pointer",
                borderBottom: "1px solid #ddd",
                backgroundColor: "#fff",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#f1f1f1")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#fff")
              }
            >
              {option.userEmail}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
