import View from "./view";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { pagination } from "../../../../utils/pagination";
import useApiGetMethodHook from "../../../../features/customHooks/apiGetMethodHook";
import { useParams } from "react-router-dom";

type Props = {};

const UsersInvoiceDeatil = (props: Props) => {
  const dummyData = {
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  };
  const [modalShow, setModalShow] = useState(false);
  const [waiveModalShow, setWaiveModalShow] = useState(false);
  const [refreshData, setRefreshData] = useState(true);
  const [studentBillData, setStudentBillData] = useState({});
  const [offlinePaymentResponse, setOfflinePaymentResponse] = useState([]);
  const [paymentMethodsData, setPaymentMethodsData] = useState(dummyData);
  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
  });

  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );
  const { id } = useParams();

  /* < --- call api to get Payment Methods --- > */
  const {
    apiData: paymentMethodsApiData,
    apiStatus: paymentMethodsApiStatus,
    refetch: paymentMethodRefetch,
  } = useApiGetMethodHook<any>(`/payment-methods`, filterUpdate, "hostel");

  // Update mealData when apiData changes
  useEffect(() => {
    if (paymentMethodsApiData) {
      setPaymentMethodsData(paymentMethodsApiData);
    }
  }, [paymentMethodsApiData]);

  /* < --- Student Bills Data --- > */
  const { apiData, apiStatus, loading, refetch } = useApiGetMethodHook<any>(
    `${currentInstitute}/hostel/bill-details/${id}`,
    filterUpdate,
    "hostel"
  );

  const {
    apiData: offlinePaymentData,
    apiStatus: offlinePaymentStatus,
    loading: offlinePaymentLoading,
    refetch: offlinePaymentRefetch,
  } = useApiGetMethodHook<any>(
    `/${id}/admin/offlinePayment`,
    filterUpdate,
    "hostel"
  );

  useEffect(() => {
    if (id !== undefined) {
      refetch();
    }
  }, [id, filterUpdate, refreshData]);

  useEffect(() => {
    if (apiData) {
      setStudentBillData(apiData);
    }
  }, [apiData]);

  useEffect(() => {
    if (id !== undefined) {
      offlinePaymentRefetch();
    }
  }, [id]);

  useEffect(() => {
    if (offlinePaymentData) {
      setOfflinePaymentResponse(offlinePaymentData);
    }
  }, [offlinePaymentData]);

  const updateInputFilters = (inputvalues: any) => {
    if (inputvalues.reset !== undefined) {
      let updatedState = { ...filterUpdate, pageNumber: 0 };
      delete updatedState.bedId;
      delete updatedState.roomId;
      delete updatedState.paymentMode;
      delete updatedState.paymentStatus;
      setFilterUpdate(updatedState);
      return false;
    }
    if (inputvalues !== "") {
      setFilterUpdate({
        ...filterUpdate,
        pageNumber: 0,
        bedId: inputvalues.bedId,
        roomId: inputvalues.roomId,
        paymentMode: inputvalues.paymentMode,
        paymentStatus: inputvalues.paymentStatus,
      });
    } else {
      let updatedState = { ...filterUpdate, pageNumber: 0 };
      updatedState.bedId = inputvalues.bedId;
      updatedState.roomId = inputvalues.roomId;
      updatedState.paymentMode = inputvalues.paymentMode;
      updatedState.paymentStatus = inputvalues.paymentStatus;
      setFilterUpdate(updatedState);
    }
  };

  // Refresh Data
  const refreshToggle = () => {
    setRefreshData(!refreshData);
  };

  /* <--- handle modal hide & show functionality ---> */
  const toggleModalShow = (status: boolean) => {
    setModalShow(status);
  };

    /* <--- handle modal hide & show functionality ---> */
    const waiveToggleModalShow = (status: boolean) => {
      setWaiveModalShow(status);
    };
  return (
    <View
      loading={loading}
      modalShow={modalShow}
      apiStatus={apiStatus}
      waiveModalShow={waiveModalShow}
      refreshToggle={refreshToggle}
      toggleModalShow={toggleModalShow}
      onHide={() => toggleModalShow(false)}
      updateInputFilters={updateInputFilters}
      studentBillData={studentBillData}
      waiveToggleModalShow={waiveToggleModalShow}
      onHides={()=> waiveToggleModalShow(false)}
      offlinePaymentResponse={offlinePaymentResponse}
      paymentMethodsData={paymentMethodsData.items}
    />
  );
};

export default UsersInvoiceDeatil;
