import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  modalShow: any;
  apiStatus: any;
  refreshToggle: any;
  toggleModalShow: any;
  onHide: any;
  onHides: any;
  loading: any;
  waiveModalShow: any;
  updateInputFilters : any;
  studentBillData: any;
  paymentMethodsData : any;
  offlinePaymentResponse: any
  waiveToggleModalShow: any;
};

const View = (props: Props) => {
  const commonProps = {
    modalShow: props.modalShow,
    apiStatus: props.apiStatus,
    waiveModalShow: props.waiveModalShow,
    refreshToggle: props.refreshToggle,
    toggleModalShow: props.toggleModalShow,
    waiveToggleModalShow: props.waiveToggleModalShow,
    onHide: props.onHide,
    onHides: props.onHides,
    updateInputFilters : props.updateInputFilters,
    studentBillData: props.studentBillData,
    paymentMethodsData : props.paymentMethodsData,
    loading: props.loading,
    offlinePaymentResponse: props.offlinePaymentResponse,
  };
 
  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;
