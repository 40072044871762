import { useState, useCallback } from "react";
import { getData as coreApiCall } from "../../adapters/coreservices";
import { getData as microApiCall } from "../../adapters/microservices";
import { getData as hostelApiCall } from "../../adapters/hostelservices";
import { handleGlobalError } from "../../globals/GlobalErrorFunction";

interface FetchState<T> {
  apiData: T | null;
  loading: boolean;
  error: string | null;
  setError: any;
  apiStatus: string;
  showErrorAlert?: boolean;
  setShowErrorAlert?: any
}

const useApiGetMethodHook = <T,>(
  URL: string,
  options?: any,
  service?: string
): FetchState<T> & { refetch: () => void } => {
  let getErrorObj = {};
  const [apiData, setApiData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [error, setError] = useState<any>({
    message: "",
    alertBoxColor: "",
    apiErrorStatus: ""
  });
  const [apiStatus, setApiStatus] = useState<string>("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    setShowErrorAlert(false)
    setApiStatus("started");
    try {
      let res;
      if (service === "core") {
        res = await coreApiCall(URL, options);
      } else if (service === "hostel") {
        res = await hostelApiCall(URL, options);
      } else {
        res = await microApiCall(URL, options);
      }

      // const res = await microApiCall(URL, options);
      if (res.data !== "" && res.status === 200) {
        setApiData(res.data);
        setApiStatus("finished");
      } else {
        setError("Error: Data not received or status not 200");
        setShowErrorAlert(true)
      }
    } catch (error: any) {
      if (error?.response?.status > 0) {
        getErrorObj = handleGlobalError(error.response.data.message, error.response.status);
      } else {
        getErrorObj = handleGlobalError("", 0);
      }
      setError(getErrorObj);
      setApiStatus("finished");
      setShowErrorAlert(true)
    } finally {
      setLoading(false);
      setApiStatus("finished");
    }
  }, [URL, options]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return { apiData, loading, error, setError, apiStatus, showErrorAlert, setShowErrorAlert, refetch: fetchData };
};

export default useApiGetMethodHook;
