import { Outlet, Route } from 'react-router-dom'
import CoPoManagement from '../../../pages/siteAdminstration/copoManagement'
import CoPoCourseManagment from '../../../pages/siteAdminstration/copoManagement/manageCourse'
import CopoTabsList from '../../../pages/siteAdminstration/copoManagement/tabsIndex'

const CoPoManagementRoute = () => (
    <Route key='copomanagement' path='copomanagement' element={<Outlet />} >
      <Route index element={<CoPoManagement />} />
      <Route key='copoCourse' path='copoCourse/:id/:name' element={<CoPoCourseManagment />} />
      <Route key='tabsList' path='copoCourse/:id/:name/tabsList/:id/:name/:cid/:cname' element={<CopoTabsList />} />   
    </Route>
)

export default CoPoManagementRoute