import View from "./view";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { pagination } from "../../../../utils/pagination";
import useApiGetMethodHook from "../../../../features/customHooks/apiGetMethodHook";
import { getData } from "../../../../adapters/hostelservices";

type Props = {};

const Payments = (props: Props) => {
  const dummyData = {
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  };
  const [modalShow, setModalShow] = useState(false);
  const [refreshData, setRefreshData] = useState(true);
  const [allHostelData, setAllHostelData] = useState(dummyData);
  const [studentBillData, setStudentBillData] = useState(dummyData);
  const [roomBedAllocations, setRoomBedAllocations] = useState({
    rooms: [],
    beds: [],
  });
  const [paymentMethodsData, setPaymentMethodsData] = useState(dummyData);
  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
    billStatus: "pending",
    roomStatus: "active",
  });

  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );
  const userId = useSelector((state: any) => state.userInfo.userInfo.uid);
  const currentUserRoleId = useSelector((state: any) => state.globalFilters.currentUserRole.id);

  // student hostel Details api to get all the room and beds allocated to the student
  const {
    apiData: roomAllocations,
    loading,
    error,
    setError,
    apiStatus: hostelDetailStatus,
    refetch: hostelDetailsRefetch,
    showErrorAlert,
    setShowErrorAlert,
  } = useApiGetMethodHook<any>(
    `/${currentInstitute}/room-allocations/get-my-allocations/${userId}`,
    filterUpdate,
    "hostel"
  );

  useEffect(() => {
    hostelDetailsRefetch();
  }, [refreshData]);

  useEffect(() => {
    getData(`/${currentInstitute}/hostels/${currentUserRoleId}/all-hostel`, filterUpdate)
      .then((result: any) => {
        if (result.data.items !== "" && result.status === 200) {
          setAllHostelData(result.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (roomAllocations?.items?.length > 0) {
      const sortData = roomAllocations.items?.sort(
        (item1: { id: number }, item2: { id: number }) => item1.id - item2.id
      );
      setRoomBedAllocations({
        rooms: sortData?.map((allocation: any) => allocation?.roomNumber),
        beds: sortData?.map((allocation: any) => allocation?.bedNumber),
      });
    }
  }, [roomAllocations]);

  /* < --- call api to get Payment Methods --- > */
  const {
    apiData: paymentMethodsApiData,
    apiStatus: paymentMethodsApiStatus,
    refetch: paymentMethodRefetch,
  } = useApiGetMethodHook<any>(`/payment-methods`, filterUpdate, "hostel");

  useEffect(() => {
    setPaymentMethodsData(dummyData);
    paymentMethodRefetch();
  }, [refreshData, filterUpdate]);

  // Update mealData when apiData changes
  useEffect(() => {
    if (paymentMethodsApiData) {
      setPaymentMethodsData(paymentMethodsApiData);
    }
  }, [paymentMethodsApiData]);

  /* < --- Student Bills Data --- > */
  const { apiData, apiStatus, refetch } = useApiGetMethodHook<any>(
    `${currentInstitute}/hostel/user-invoice`,
    filterUpdate,
    "hostel"
  );

  useEffect(() => {
    setStudentBillData(dummyData);
    refetch();
  }, [refreshData, filterUpdate]);

  useEffect(() => {
    if (apiData) {
      setStudentBillData(apiData);
    }
  }, [apiData]);

  const updateInputFilters = (inputValues: any) => {
    if (inputValues.reset) {
      setFilterUpdate({
        pageNumber: 0,
        pageSize: 10,
        billStatus: "pending",
        roomStatus: "active",
      });
      return;
    }
  
    setFilterUpdate((prevFilters: any) => {
      const newFilters = { ...prevFilters, ...inputValues, pageNumber: 0 }; // Reset page on filter update
  
      // Remove keys that have an empty string as a value
      Object.keys(newFilters).forEach((key) => {
        if (newFilters[key] === "") {
          delete newFilters[key];
        }
      });
  
      return newFilters;
    });
  };
  

  // Refresh Data
  const refreshToggle = () => {
    setRefreshData(!refreshData);
  };

  /* <--- handle modal hide & show functionality ---> */
  const toggleModalShow = (status: boolean) => {
    setModalShow(status);
  };

  return (
    <View
      modalShow={modalShow}
      apiStatus={apiStatus}
      refreshToggle={refreshToggle}
      toggleModalShow={toggleModalShow}
      onHide={() => toggleModalShow(false)}
      allHostelData={allHostelData?.items}
      updateInputFilters={updateInputFilters}
      studentBillData={studentBillData.items}
      roomBedAllocations={roomBedAllocations}
      paymentMethodsData={paymentMethodsData.items}
    />
  );
};

export default Payments;
