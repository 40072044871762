import React from "react";
import "sweetalert2/src/sweetalert2.scss";
import SimpleMail from "../../simpleMail";
import { Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
  commonProps: {
    apiStatus: any;
    mailConfigData: any;
  };
};

const Mobile = (props: Props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="contentarea-wrapper mb-wraper">
        <Container fluid className="mt-3">
          <Card className="p-4 shadow-sm mb-4">
            <SimpleMail
              apiStatus={props.commonProps.apiStatus}
              mailConfigData={props.commonProps.mailConfigData}
            />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Mobile;
