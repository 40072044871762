import $ from "jquery";

$(document).on("click", ".hamburger, .chevrondown-par", function () {
    const isOpening = $(this).hasClass("hamburger");
    $(".verticalPrimaryMenu, .site-header, .breadcrumb-box, .contentarea-wrapper, .site-footer").toggleClass("active", isOpening);
    $(".hamburger").toggleClass("d-none", isOpening);
    $(".chevrondown-par").toggleClass("d-none", !isOpening);
    $(".secondary-logo").toggleClass("d-block", isOpening);
    $(".site-logo .bl-logo").toggleClass("d-none", isOpening);
});

$(document).on("click", function (e) {
    if (!$(e.target).closest(".hamburger").length) {
        $(".verticalPrimaryMenu, .site-header, .breadcrumb-box, .contentarea-wrapper, .site-footer").removeClass("active");
        $(".hamburger").removeClass("d-none");
        $(".chevrondown-par").addClass("d-none");
        $(".secondary-logo").removeClass("d-block");
        $(".site-logo .bl-logo").removeClass("d-none");
    }
});