import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TimerAlertBox from "../../widgets/alert/timerAlert";
import { Field, Form, Formik, FormikHelpers } from "formik";
import FieldLabel from "../../widgets/formInputFields/labels";
import WaveBottom from "../../assets/images/background/bg-modal.svg";
import FieldTypeText from "../../widgets/formInputFields/formTextField";
import FieldErrorMessage from "../../widgets/formInputFields/errorMessage";
import FieldTypeCheckbox from "../../widgets/formInputFields/formCheckboxField";
import FieldTypeTextarea from "../../widgets/formInputFields/formTextareaField";
import CustomButton, {
  LoadingButton,
} from "../../widgets/formInputFields/buttons";
import { Row, Col } from "react-bootstrap"; // Import Bootstrap Row and Col
import Multiselect from "multiselect-react-dropdown";
import { format } from "date-fns";
import SearchComponent from "../../widgets/search";
import FieldTypeRadio from "../../widgets/formInputFields/formRadioField";

type Props = {
  error?: any;
  modalContent: any[];
  initialValues?: any;
  validationSchema?: any;
  showErrorAlert?: boolean;
  conditionFlag: { id: number };
  modalShow?: boolean | undefined;
  onChange?: (() => void) | undefined;
  size?: "sm" | "lg" | "xl" | undefined;
  onModalHide?: (() => void) | undefined;
  setShowErrorAlert: (params: boolean) => void;
  modalHeading?: { ifCondition: string; elseCondition: string };
  handleFormSubmit: (arg0: any, arg1: FormikHelpers<any>) => void;
  getPutError?: any;
  getPutShowErrorAlert?: any;
  getSetShowErrorAlert?: any;
};

const ReusableModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      centered
      size={props.size}
      onHide={props.onModalHide}
      show={props.modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-design-wrapper"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.conditionFlag.id === 0
            ? props.modalHeading?.ifCondition
            : props.modalHeading?.elseCondition}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* for post api error handling */}
        {props.error !== null &&
          props.error?.message !== "" &&
          props.showErrorAlert && (
            <TimerAlertBox
              alertMsg={props.error.message}
              className="mt-3"
              variant={props.error.alertBoxColor}
              setShowAlert={props.setShowErrorAlert}
              showAlert={props.showErrorAlert}
              status={props.error?.apiErrorStatus}
            />
          )}

        {/* for put api error handling */}
        {props.getPutError !== null &&
          props.getPutError?.message !== "" &&
          props.getPutShowErrorAlert && (
            <TimerAlertBox
              alertMsg={props.getPutError.message}
              className="mt-3"
              variant={props.getPutError.alertBoxColor}
              setShowAlert={props.setShowErrorAlert}
              showAlert={props.getPutShowErrorAlert}
              status={props.getPutError?.apiErrorStatus}
            />
          )}
        <Formik
          initialValues={props.initialValues}
          validationSchema={props.validationSchema}
          onSubmit={(values, action) => {
            props.handleFormSubmit(values, action);
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            resetForm,
            setFieldValue,
            values,
            handleChange,
          }) => (
            <Form>
              {props.modalContent.map((field: any, index: number) => {
                // Handle single column layout
                if (field.layout === "col") {
                  return (
                    <Row key={index}>
                      <Col>
                        {renderField(
                          field,
                          errors,
                          touched,
                          setFieldValue,
                          values,
                          handleChange,
                          t
                        )}
                      </Col>
                    </Row>
                  );
                }

                // Handle grouped row layout
                if (field.layout === "row") {
                  return (
                    <Row key={index}>
                      {field.fields.map((subField: any, subIndex: number) => (
                        <Col key={subIndex} sm={6}>
                          {renderField(
                            subField,
                            errors,
                            touched,
                            setFieldValue,
                            values,
                            handleChange,
                            t
                          )}
                        </Col>
                      ))}
                    </Row>
                  );
                }

                return null;
              })}

              {isSubmitting ? (
                <LoadingButton
                  variant="primary"
                  btnText={
                    props.conditionFlag.id === 0
                      ? "Submitting..."
                      : "Updating..."
                  }
                  className="modal-buttons"
                />
              ) : (
                <div className="modal-buttons">
                  <CustomButton
                    type="submit"
                    variant="primary"
                    btnText={
                      props.conditionFlag.id === 0
                        ? t("common.submit")
                        : t("common.update")
                    }
                  />{" "}
                  {props.conditionFlag.id === 0 && (
                    <CustomButton
                      type="reset"
                      btnText={t("common.reset")}
                      variant="outline-secondary"
                      onClick={() => {
                        resetForm();
                        props.setShowErrorAlert(false);
                      }}
                    />
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <img src={WaveBottom} alt="WaveBottom" className="wavebg" />
    </Modal>
  );
};

// Helper function to render fields dynamically
const renderField = (
  field: any,
  errors: any,
  touched: any,
  setFieldValue: any,
  values: any,
  handleChange: any,
  t : any
) => {

  switch (field.type) {
    case "text":
    case "tel":
    case "number":
      return (
        <div className="mb-3">
          <FieldLabel
            htmlfor={field.htmlfor}
            labelText={field.labelText}
            required={field.required}
            star={field.star}
          />
          <FieldTypeText
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            disabled={field.disabled}
            min={field.min || "0"}
          />
          <FieldErrorMessage
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        </div>
      );
    case "checkbox":
      return (
        <div className="mb-3">
          <FieldTypeCheckbox
            name={field.name}
            checkboxLabel={field.checkboxLabel}
          />
          <FieldErrorMessage
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        </div>
      );
    case "select":
      return (
        <div className="mb-3">
          <FieldLabel
            htmlfor={field.htmlfor}
            labelText={field.labelText}
            required={field.required}
            star={field.star}
          />
          <Field
            as="select"
            name={field.name}
            className="form-select"
            disabled={field.disabled}
          >
            <option value="">{t('common.select')} {field.labelText}</option>
            {field.options?.length > 0 ? (
              field.options.map((item: any) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))
            ) : (
              <option disabled>No options available</option>
            )}
          </Field>
          <FieldErrorMessage
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        </div>
      );
    case "textarea":
      return (
        <div className="mb-3">
          <FieldLabel
            htmlfor={field.htmlfor}
            labelText={field.labelText}
            required={field.required}
            star={field.star}
          />
          <FieldTypeTextarea
            name={field.name}
            placeholder={field.placeholder}
            component={field.type}
          />
          <FieldErrorMessage
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        </div>
      );
    case "date":
      return (
        <div className="mb-3">
          <FieldLabel
            htmlfor={field.htmlfor}
            labelText={field.labelText}
            required={field.required}
            star={field.star}
          />
          <FieldTypeText
            type="date"
            name={field.name}
            min={new Date()}
            disabled={field.disabled}
          />
          <FieldErrorMessage
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        </div>
      );

    case "multiselect":
      return (
        <div>
          <FieldLabel
            htmlfor={field.htmlfor}
            labelText={field.labelText}
            required={field.required}
            star={field.star}
          />
          <Multiselect
            className="mb-3"
            displayValue="name"
            placeholder={field.placeholder}
            options={field.options}
            // options={props.preferenceData.map((item: any) => ({ ...item, name: capitalizeFirstWords(item.name) }))}
            selectedValues={field.options.filter((pref: { id: any }) =>
              values[field.name].includes(pref.id)
            )}
            onSelect={(selectedList) => {
              const selectedIds = selectedList.map((item: any) => item.id);
              setFieldValue(field.name, selectedIds);
            }}
            onRemove={(removedList) => {
              const remainingIds = removedList.map((item: any) => item.id);
              setFieldValue(field.name, remainingIds);
            }}
            showCheckbox
            style={{ ...multiSelectStyle }}
          />

          <FieldErrorMessage
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        </div>
      );

    // case "number":
    //   return (
    //     <div className="mb-3">
    //       <FieldLabel
    //         htmlfor={field.htmlfor}
    //         labelText={field.labelText}
    //         required={field.required}
    //         star={field.star}
    //       />
    //       <FieldTypeText
    //         type="number"
    //         min={field.min || '0'}
    //         name={field.name}
    //         disabled={field.disabled}
    //         placeholder={field.placeholder}
    //       />
    //       <FieldErrorMessage
    //         errors={errors[field.name]}
    //         touched={touched[field.name]}
    //       />
    //     </div>
    //   );

    case "datetime-local":
      return (
        <div className="mb-3">
          <FieldLabel
            htmlfor={field.htmlfor}
            labelText={field.labelText}
            required={field.required}
            star={field.star}
          />
          <FieldTypeText
            type="datetime-local"
            name={field.name}
            placeholder={field.placeholder}
            value={
              values.entryDateTime ? formatDateTime(values.entryDateTime) : ""
            }
          />
          <FieldErrorMessage
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        </div>
      );

    // case "tel":
    //   return (
    //     <div className="mb-3">
    //       <FieldLabel
    //         htmlfor={field.htmlfor}
    //         labelText={field.labelText}
    //         required={field.required}
    //         star={field.star}
    //       />
    //       <FieldTypeText type="tel" name={field.name} placeholder="+91" />
    //       <FieldErrorMessage
    //         errors={errors[field.name]}
    //         touched={touched[field.name]}
    //       />
    //     </div>
    //   );

    case "search":
      return (
        <div className="mb-3">
          <FieldLabel
            htmlfor={field.htmlfor}
            labelText={field.labelText}
            required={field.required}
            star={field.star}
          />
          <SearchComponent
            endPoint={field.endPoint}
            name={field.name}
            value={values.email}
            // disabled={!boolStatus && id === undefined}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
          <FieldErrorMessage
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        </div>
      );
    default:
      return null;
  }
};

export default ReusableModal;

const multiSelectStyle = {
  chips: {
    background: "#0d6efd",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
};

// Format to "yyyy-MM-dd'T'HH:mm:ss"
const formatDateTime = (date: string) => {
  const formattedDate = new Date(date);
  return format(formattedDate, "yyyy-MM-dd'T'HH:mm:ss");
};
