import React from "react";
import CourseModal from "../../form";
import CourseTable from "../../table";
import { Container } from "react-bootstrap";
import PageTitle from "../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  commonProps: {
    programId: any;
    modalShow: any;
    apiStatus: any;
    courseObj: any;
    totalPages: any;
    filterUpdate: any;
    refreshToggle: any;
    newPageRequest: any;
    addCourseModal: any;
    cleanFormValues: any;
    toggleModalShow: any;
    editHandlerById: any;
    sortedCategories: any;
    coursePermission: any;
    configPermission: any;
    toggleCourseModal: any;
    setFormParentValue: any;
    setFormWeightValue: any;
    updateDeleteRefresh: any;
    setEditCategoryValues: any;
  };
};


const Mobile = (props: Props) => {
  const { _, name } = useParams();
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <BreadcrumbComponent
        routes={[
          { name: t("common.home"), path: "/home" },
          { name: t('common.siteAdmin'), path: "/siteadmin" },
          { name: t('naac.coPoMgmt'), path: "/siteadmin/copomanagement" },
          { name: name, path: "" },
          ]}
      />
      <div className="contentarea-wrapper mb-wraper">
          <Container fluid className="mt-3">
            <PageTitle pageTitle={`${t('naac.configCoPoProgram')}: <span>${name}</span>`} gobacklink="/siteadmin/copomanagement" />
            {props.commonProps.coursePermission.canView && (
              <CourseTable
                programId={props.commonProps.programId}
                apiStatus={props.commonProps.apiStatus}
                modalShow={props.commonProps.modalShow}
                categoryData={props.commonProps.sortedCategories}
                toggleModalShow={props.commonProps.toggleModalShow}
                refreshcategories={props.commonProps.refreshToggle}
                cleanFormValues={props.commonProps.cleanFormValues}
                editHandlerById={props.commonProps.editHandlerById}
                coursePermission={props.commonProps.coursePermission}
                configPermission={props.commonProps.configPermission}
                toggleCourseModal={props.commonProps.toggleCourseModal}
                setFormParentValue={props.commonProps.setFormParentValue}
                setFormWeightValue={props.commonProps.setFormWeightValue}
                updatedeleterefresh={props.commonProps.updateDeleteRefresh}
                setEditCategoryValues={props.commonProps.setEditCategoryValues}
              />
            )}
          </Container>
      </div>
      <CourseModal
        show={props.commonProps.addCourseModal}
        courseobj={props.commonProps.courseObj}
        programId={props.commonProps.programId}
        updateAddRefresh={props.commonProps.refreshToggle}
        refreshcategories={props.commonProps.refreshToggle}
        toggleCourseModal={props.commonProps.toggleCourseModal}
        onHide={() => props.commonProps.toggleCourseModal(false)}
      />
    </React.Fragment>
  );
};

export default Mobile;
