import React from "react";
import { Container } from "react-bootstrap";
import PageTitle from "../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../widgets/breadcrumb";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

type Props = {
  commonProps: {
    adminRawData: any[];
    renderComponent: any;
  };
};

const Mobile = (props: Props) => {
  const {t} = useTranslation();
  const permissions = useSelector((state: any) => state.userAuthorities.permissions);
 const currentUserRole = useSelector((state: any) => state.globalFilters.currentUserRole);
  return (
    <React.Fragment>
      <BreadcrumbComponent routes={[{ name: t("common.home"), path: "/home" },{ name: t('common.siteAdmin'), path: "" }]} />
      <div className="contentarea-wrapper mb-wraper">
          <Container fluid className="mt-3">
            <PageTitle
              pageTitle={t('common.siteAdmin')}
              gobacklink={`${(permissions.menu.dashboard.canView && currentUserRole.shortName !== "admin") ? "/dashboard" : "/performance"}`}
            />
          </Container>
          {props.commonProps.adminRawData.map((item: any, index: number) => (
            <Container fluid
              key={index}
              className={`administration-box row${index + 1}`}
            >
              {item.map((item: any, index: number) =>
                props.commonProps.renderComponent(item, index)
              )}
            </Container>
          ))}
      </div>
    </React.Fragment>
  );
};

export default Mobile;
