import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import DeleteAlert from "../../../widgets/alert/deleteAlert";
import DeleteAlert from "../../../../../widgets/alert/deleteAlert";
import "../../../../hostelManagement/style.scss";
import sweetSuccessAlert from "../../../../../widgets/alert/sweetAlert";
import editIcon from "../../../../../assets/images/icons/edit-action.svg";
import showIcon from "../../../../../assets/images/icons/show-action.svg";
import hideIcon from "../../../../../assets/images/icons/hide-action.svg";
import { deleteData, putData } from "../../../../../adapters/hostelservices";
import deleteIcon from "../../../../../assets/images/icons/delete-action.svg";
import { globalAlertActions } from "../../../../../store/slices/globalAlerts";
import ReusableReactTable from "../../../../../components/reusableReactTable";
import { capitalizeFirstWords } from "../../../../../globals/titleCapitalize/capitalizeFirstWords";

const PaymentMethodTable = ({
  toggleModalShow,
  apiStatus,
  paymentMethodsData,
  editHandlerById,
  refreshdata,
  error,
  setError,
  showErrorAlert,
  setShowErrorAlert,
}: any) => {
  const { t } = useTranslation();

  const tableColumn = [
    {
      Header: t("common.sNo"),
      accessor: "sno",
      Cell: ({ row }: any) => row.index + 1,
    },
    {
      Header: t("common.name"),
      accessor: "name",
      Cell: ({ row }: any) => {
        const Name = row.original.name || "";
        return <span>{capitalizeFirstWords(Name) || "-"}</span>;
      },
    },
    {
      Header: t('hmd.shortNames'),
      accessor: "shortName",
      Cell: ({ row }: any) => {
        const shortName = row.original.shortName || "";
        return <span>{shortName || "-"}</span>;
      },
    },
    {
      Header: t("common.action"),
      Cell: ({ row }: any) => (
        <div className="action-icons-box">
          <Link className="action-icons" to="">
            <img
              src={editIcon}
              alt="Edit"
              onClick={() =>
                editHandler({
                  id: row.original.id,
                  name: row.original.name,
                  shortName: row.original.shortName,
                })
              }
            />
          </Link>

          <Link className="action-icons" to="">
            <img
              src={deleteIcon}
              alt="Delete"
              onClick={() => deleteHandler(row.original.id)}
            />
          </Link>

          {/* <Link
            className="action-icons"
            to=""
            onClick={() => {
              toggleTopicPublished(row.original);
            }}
          >
            <img
              src={row.original.published !== false ? showIcon : hideIcon}
              alt="Show"
            />
          </Link> */}
        </div>
      ),
    },
  ];

  // react table custom variable decleration === >>>
  const [deleteId, setDeleteId] = useState(0);
  const [onDeleteAction, setOnDeleteAction] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [facilityToggle, setPaymentMethodToggle] = useState(false);

  useEffect(() => {
    if (onDeleteAction === t("common.yes")) {
      refreshdata(false);
      let endPoint = `/payment-methods/${deleteId}`;
      deleteData(endPoint)
        .then((res: any) => {
          if (res.status === 200) {
            refreshdata(true);
            sweetSuccessAlert({
              text: t('hmd.paymentMethodSuccDeleted'),
            });
          }
        })
        .catch((result: any) => {
          if (result.response.status === 400) {
            setShowErrorAlert(true);
            setError({
              message: result.response.data.message,
              alertBoxColor: "danger",
            });
          } else if (result.response.status === 500) {
            setShowErrorAlert(true);
            setError({
              message: result.response.data.message,
              alertBoxColor: "danger",
            });
          }
        });
    }
    setOnDeleteAction("");
  }, [onDeleteAction]);

  const deleteHandler = (facilityid: number) => {
    refreshdata(false);
    setShowDeleteModal(true);
    setDeleteId(facilityid);
  };

  // getting onDelete Modal Action === >>>
  const deleteActionResponse = (action: string) => {
    setOnDeleteAction(action);
    setShowDeleteModal(false);
  };

  // edit event handler === >>>
  const editHandler = ({ id, name, shortName }: any) => {
    toggleModalShow(true);
    editHandlerById({
      id,
      name,
      shortName
    });
  };

  const toggleTopicPublished = (programPacket: any) => {
    programPacket.published = !programPacket.published;
    setPaymentMethodToggle((prevState) => !prevState);
    let endPoint = `/payment-methods/${programPacket.id}`;
    putData(endPoint, programPacket)
      .then((res: any) => {
        setPaymentMethodToggle((prevState) => !prevState);
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response.status === 400) {
          setShowErrorAlert(true);
          setError({
            message: err.response.data.message,
            alertBoxColor: "danger",
          });
        }
        // dispatch(
        //   globalAlertActions.globalAlert({
        //     alertMsg: "Action failed due to some error",
        //     status: true,
        //   })
        // );
        programPacket.published = !programPacket.published;
        setPaymentMethodToggle((prevState) => !prevState);
      });
  };

  return (
    <>
      <div className="table-responsive admin-table-wrapper mt-3">
        <ReusableReactTable
          columns={tableColumn}
          data={paymentMethodsData}
          loading={apiStatus}
          error={error}
          showErrorAlert={showErrorAlert}
          setShowErrorAlert={setShowErrorAlert}
        />
      </div>
      <DeleteAlert
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        deleteActionResponse={deleteActionResponse}
        modalHeading={"Payment Method"}
      />
    </>
  );
};

export default PaymentMethodTable;
