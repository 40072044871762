import React from 'react';
import { Outlet, Route } from 'react-router-dom'

const Preview = React.lazy(() => import("../../../pages/CommonComponent/preview"))   
const SiteAdminHome = React.lazy(() => import("../../../pages/siteAdminstration/siteAdmin"))
const ManageUsers = React.lazy(() => import("../../../pages/siteAdminstration/manageUsers"))   
const EnrolUsers = React.lazy(() => import('../../../pages/siteAdminstration/enrolment/enrolUser'))   
const ManageGroups = React.lazy(() => import('../../../pages/siteAdminstration/enrolment/manageGroups'))   
const ProgramEnrollment = React.lazy(() => import('../../../pages/siteAdminstration/programEnrollment'))   
const ManageProgramEnrollment = React.lazy(() => import('../../../pages/siteAdminstration/manageUsers'))   
const EnrolUserToProgram = React.lazy(() => import('../../../pages/siteAdminstration/manageUsers/addUsers'))   
const CourseEnrollment = React.lazy(() => import('../../../pages/siteAdminstration/enrolment/courseenrollment'))
const UserWaitlist = React.lazy(() => import('../../../pages/siteAdminstration/enrolment/enrolUser/userWaitlist'))

const ProgramEnrollmentRoute = () => (
    <Route path="programenrollment" key="programenrollment" element={<Outlet />} >
      <Route index element={<ProgramEnrollment />} />
      <Route path="preview" key="preview" element={<Preview />} />
      {/* <Route path="siteadmin" key="siteadmin" element={<SiteAdminHome />} />, */}
      <Route path="manageusers" key="manageusers" element={<ManageUsers />} />
      <Route path="enrolusertoprogram/:programid/:userid/:name" key="enrolusertoprogram" element={<EnrolUserToProgram />} />
      <Route path="manageprogramenrollment/:programid/:programname" key="manageprogramenrollment" element={<Outlet />} >
        <Route index element={<ManageProgramEnrollment />} />
        <Route path="enrolusers/:id/:name" key="enrolusers" element={<Outlet />} >
        <Route index element={<EnrolUsers />} />
          <Route path="courseenrollment/:programid/:name/:courseid/:coursename" key="courseenrollment" element={<Outlet />} >
          <Route index element={<CourseEnrollment />} />
            {/* <Route path="managegroups" key="managegroups" element={<ManageGroups />} /> */}
            <Route path="managegroups/:programid/:name/:courseid/:coursename" key="managegroups" element={<ManageGroups />} />
          </Route>
          <Route path="userwaitlist/:programid/:name/:courseid/:coursename" element={<UserWaitlist />} />
        </Route>
      </Route>
    </Route>
 )

export default ProgramEnrollmentRoute