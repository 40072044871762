import { useTable } from "react-table";
import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Errordiv from "../../widgets/alert/errordiv";
import TableSkeleton from "../../widgets/skeleton/table";
import TimerAlertBox from "../../widgets/alert/timerAlert";

interface ReusableReactTableProps {
  data: any[];
  columns: any[];
  loading: any;
  
  error?: any;
  showErrorAlert?: any;
  setShowErrorAlert?: any
}

const ReusableReactTable: React.FC<ReusableReactTableProps> = ({
  columns,
  data,
  loading,

  error ,
  showErrorAlert ,
  setShowErrorAlert 
}) => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data || [], [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: memoizedColumns,
      data: memoizedData,
    });

  return (
    <>
      {error !== null && error?.message !== "" && showErrorAlert && (
        <TimerAlertBox
          alertMsg={error.message}
          className="mt-3"
          variant={error.alertBoxColor}
          setShowAlert={setShowErrorAlert}
          showAlert={showErrorAlert}
          status={error?.apiErrorStatus}
        />
      )}

      <Table striped borderless hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} key={cell.column.id}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {loading == "started" && memoizedData.length === 0 && (
        <TableSkeleton numberOfRows={5} numberOfColumns={4} />
      )}
      {loading == "finished" && memoizedData.length === 0 && (
        <Errordiv msg={t("common.noRecord")} cstate className="mt-3" />
      )}
    </>
  );
};

export default ReusableReactTable;
