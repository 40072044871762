import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import chevrondown from "../../assets/images/primarymenu/chevrondown.png";
import logowhite from "../../assets/images/primarymenu/logo.png";
import home from "../../assets/images/primarymenu/home.png";
import dashboard from "../../assets/images/primarymenu/dashboard.png";
import calendar from "../../assets/images/primarymenu/calendar.png";
import performance from "../../assets/images/primarymenu/performance.png";
import gradebook from "../../assets/images/primarymenu/gradebook.png";
import attendance from "../../assets/images/primarymenu/attendance.png";
import myhostel from "../../assets/images/primarymenu/myhostel.png";
import siteadmin from "../../assets/images/primarymenu/siteadmin.png";
import helpdesk from "../../assets/images/primarymenu/helpdesk.png";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";

function Verticalmenu() {
  const location = useLocation();

  const menuPermission = useSelector(
    (state: any) => state.userAuthorities.permissions.menu
  );

  const authenticatedUserPermission = useSelector(
    (state: any) => state.authenticatedUser.permissions.menu
  );

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );
  return (
    <div className="verticalPrimaryMenu">
      <div className="vpm-container">
        <button
          type="button"
          className="btn btn-link hamburger"
          data-toggle="tooltip"
          data-placement="right"
          title="Toggle sidebar"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <button
          type="button"
          className="btn btn-link chevrondown-par d-none"
          data-toggle="tooltip"
          data-placement="right"
          title="Toggle sidebar"
        >
          <img src={chevrondown} alt="chevrondown" className="img-fluid" />
        </button>
        <div className="secondary-logo">
          <a href="#">
            <img src={logowhite} alt="Sitename" />
          </a>
        </div>
        <ul className="primarymenu">
          <li>
            <Link
              to="/home"
              className={location.pathname === "/home" ? "active-menu" : ""}
            >
              <img src={home} alt="Home" />
              <h5>Home</h5>
            </Link>
          </li>
          {((menuPermission.dashboard.canView &&
            ["student", "teacher", "editingteacher"].includes(
              currentUserRole.shortName
            )) ||
            (authenticatedUserPermission.dashboard.canView &&
              currentUserRole.id === 0)) && (
            <li>
              <Link
                to="/dashboard"
                className={
                  location.pathname === "/dashboard" ? "active-menu" : ""
                }
              >
                <img src={dashboard} alt="Dashboard" />
                <h5>Dashboard</h5>
              </Link>
            </li>
          )}

          {menuPermission.calendar.canView && currentUserRole.id !== 0 && (
            <li>
              <Link
                to="/calender"
                className={
                  location.pathname === "/calender" ? "active-menu" : ""
                }
              >
                <img src={calendar} alt="Calendar" />
                <h5>Calendar</h5>
              </Link>
            </li>
          )}

          {menuPermission.performance.canView && currentUserRole.id !== 0 ? (
            <li>
              <NavLink
                to="/performance"
                className={({ isActive }) => isActive ? "active-menu" : ""}
              >
                <img src={performance} alt="Performance" />
                <h5>Performance</h5>
              </NavLink>
            </li>
          ) : (
            authenticatedUserPermission.dashboard.canView &&
            currentUserRole.id === 0 && (
              <li>
                <NavLink
                  to=""
                  className={({ isActive }) => isActive ? "active-menu" : ""}
                >
                  <img src={performance} alt="Performance" />
                  <h5>Performance</h5>
                </NavLink>
              </li>
            )
          )}

          {menuPermission.gradebook.canView && currentUserRole.id !== 0 ? (
            <li>
              <NavLink
                to="/gradebook"
                className={({ isActive }) => isActive ? "active-menu" : ""}
              >
                <img src={gradebook} alt="Gradebook" />
                <h5>Gradebook</h5>
              </NavLink>
            </li>
          ) : (
            authenticatedUserPermission.gradebook.canView &&
            currentUserRole.id === 0 && (
              <li>
                <NavLink
                  to=""
                  className={({ isActive }) => isActive ? "active-menu" : ""}
                >
                  <img src={gradebook} alt="Gradebook" />
                  <h5>Gradebook</h5>
                </NavLink>
              </li>
            )
          )}

          {menuPermission.attendance.canView && currentUserRole.id !== 0 ? (
            <li>
              <NavLink
                to="/attendance"
                className={({ isActive }) => isActive ? "active-menu" : ""}
              >
                <img src={attendance} alt="Attendance" />
                <h5>Attendance</h5>
              </NavLink>
            </li>
          ) : (
            authenticatedUserPermission.gradebook.canView &&
            currentUserRole.id === 0 && (
              <li>
                <NavLink
                  to=""
                  className={({ isActive }) => isActive ? "active-menu" : ""}
                >
                  <img src={attendance} alt="Attendance" />
                  <h5>Attendance</h5>
                </NavLink>
              </li>
            )
          )}

          {currentUserRole.shortName === "student" && (
            <li>
              <NavLink
                to="/myhostel"
                className={({ isActive }) => isActive ? "active-menu" : ""}
              >
                <img src={myhostel} alt="My Hostel" />
                <h5>My Hostel</h5>
              </NavLink>
            </li>
          )}

          {menuPermission.admin.canView ? (
            <li>
              <NavLink
                to="/siteadmin"
                className={({ isActive }) => isActive ? "active-menu" : ""}
              >
                <img src={siteadmin} alt="Administration" />
                <h5>Administration</h5>
              </NavLink>
            </li>
          ) : (
            ["hosteladmin", "hostelwarden"].includes(
              currentUserRole.shortName
            ) && (
              <li>
                <NavLink to="/siteadmin" className={({ isActive }) => isActive ? "active-menu" : ""}>
                  <img src={siteadmin} alt="Administration" />
                  <h5>Administration</h5>
                </NavLink>
              </li>
            )
          )}
        </ul>

        <NavLink to="/helpdesk" className={({ isActive }) => `helpDeskMenu ${isActive ? "active-menu" : ""}`}>
          <img src={helpdesk} alt="Help Desk" />
          <h5>Help Desk</h5>
        </NavLink>
      </div>
    </div>
  );
}

export default Verticalmenu;
