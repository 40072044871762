import * as Yup from "yup";
import Swal from "sweetalert2";
import { Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { putData } from "../../../adapters/coreservices";
import TimerAlertBox from "../../../widgets/alert/timerAlert";
import RouterLadyLoader from "../../../globals/globalLazyLoader/routerLadyLoader";
import CustomButton, {
  LoadingButton,
} from "../../../widgets/formInputFields/buttons";

type Props = {
  mailConfigData: any;
  apiStatus: string;
};

const SimpleMail = (props: Props) => {
  const { t } = useTranslation();
  const { mailConfigData, apiStatus } = props;

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState({
    message: "",
    alertBoxColor: "",
  });

  const [initialValues, setInitialValues] = useState({
    id: "",
    hostname: "",
    portNumber: "",
    username: "",
    password: "",
    shortName: "",
    fromMail: "",
  });

  useEffect(() => {
    if (mailConfigData && mailConfigData.length > 0) {
      setInitialValues({
        id: mailConfigData[0]?.id,
        hostname: mailConfigData[0]?.hostname || "",
        portNumber: mailConfigData[0]?.portNumber || "",
        username: mailConfigData[0]?.username || "",
        password: mailConfigData[0]?.password || "",
        shortName: mailConfigData[0]?.shortName || "",
        fromMail: mailConfigData[0]?.fromMail || "",
      });
    }
  }, [mailConfigData]);

  const handleFormSubmit = (values: any, { setSubmitting, resetForm }: any) => {
    const payload = {
      id: values.id,
      hostname: values.hostname,
      portNumber: values.portNumber,
      username: values.username,
      password: values.password,
      shortName: values.shortName,
      fromMail: values.fromMail,
    };
    setSubmitting(true);
    putData(`/smtp-config`, payload)
      .then((result: any) => {
        if (result.data !== "" && result.status === 200) {
          setSubmitting(false);
          Swal.fire({
            timer: 3000,
            width: "25em",
            color: "#666",
            icon: "success",
            background: "#e7eef5",
            showConfirmButton: false,
            text: "Successfully Updated",
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        setSubmitting(false);
        if (err.response.status === 500) {
          setShowAlert(true);
          setAlertMsg({
            message: `${err.response.data.message}.`,
            alertBoxColor: "danger",
          });
        } else if (err.response.status === 400) {
          setShowAlert(true);
          setAlertMsg({
            message: `${err.response.data.message}.`,
            alertBoxColor: "danger",
          });
        }
      });
  };

  return (
    <React.Fragment>
      <TimerAlertBox
        alertMsg={alertMsg.message}
        className="mt-3"
        variant={alertMsg.alertBoxColor}
        setShowAlert={setShowAlert}
        showAlert={showAlert}
      />
      {apiStatus === "started" ? (
        <RouterLadyLoader status={true} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, action) => {
            handleFormSubmit(values, action);
          }}
        >
          {({ isSubmitting, handleChange, values }) => (
            <Form>
              <Row>
                <Col className="mb-3">
                  <label htmlFor="username">Username</label>
                  <Field
                    type="text"
                    name="username"
                    className="form-control"
                    value={values.username}
                    onChange={handleChange}
                  />
                </Col>
                <Col className="mb-3">
                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    value={values.password}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label htmlFor="hostname">Host Name</label>
                  <Field
                    type="text"
                    name="hostname"
                    className="form-control"
                    value={values.hostname}
                    onChange={handleChange}
                  />
                </Col>
                <Col className="mb-3">
                  <label htmlFor="portNumber">Port Number</label>
                  <Field
                    type="text"
                    name="portNumber"
                    className="form-control"
                    value={values.portNumber}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <label htmlFor="fromMail">From Email</label>
                  <Field
                    type="text"
                    name="fromMail"
                    className="form-control"
                    value={values.fromMail}
                    onChange={handleChange}
                  />
                </Col>
                <Col></Col>
              </Row>
              {isSubmitting === false ? (
                <div className="modal-buttons my-3">
                  <CustomButton
                    type="submit"
                    variant="primary"
                    isSubmitting={isSubmitting}
                    btnText={t("common.submit")}
                  />
                </div>
              ) : (
                <LoadingButton
                  variant="primary"
                  btnText={t("common.submitting")}
                  className="modal-buttons"
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};

export default SimpleMail;
