import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  error: any;
  onHide: any;
  loading: any;
  paymentMethodObj: any;
  paymentMethodsData: any;
  modalShow: any;
  apiStatus: any;
  permissions: any;
  totalPages : any;
  filterUpdate: any;
  refreshToggle: any;
  showErrorAlert: any
  toggleModalShow: any;
  editHandlerById: any;
  newPageRequest  : any
  openAddMealModal: any;
  setShowErrorAlert: any
  updateInputFilters: any;
  refreshOnDeleteToggle: any;
};

const View = (props: Props) => {
  const commonProps = {
    error: props.error,
    onHide: props.onHide,
    loading: props.loading,
    paymentMethodObj: props.paymentMethodObj,
    paymentMethodsData: props.paymentMethodsData,
    apiStatus: props.apiStatus,
    modalShow: props.modalShow,
    totalPages : props.totalPages,
    permissions: props.permissions,
    filterUpdate: props.filterUpdate,
    refreshToggle: props.refreshToggle,
    showErrorAlert: props.showErrorAlert,
    newPageRequest : props.newPageRequest,
    toggleModalShow: props.toggleModalShow,
    editHandlerById: props.editHandlerById,
    openAddMealModal: props.openAddMealModal,
    setShowErrorAlert: props.setShowErrorAlert,
    updateInputFilters: props.updateInputFilters,
    refreshOnDeleteToggle: props.refreshOnDeleteToggle,
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;
