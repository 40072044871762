import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
const sweetSuccessAlert = ({ text, icon = "success", timer = 3000 }: any) => {
  Swal.fire({
    timer,
    width: "25em",
    color: "#666",
    icon,
    background: "#e7eef5",
    showConfirmButton: false,
    text,
  });
};

export default sweetSuccessAlert;
