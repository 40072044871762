import { Container } from "react-bootstrap";
import PageTitle from "../../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";
import Filter from "../../filter";
import BuildPagination from "../../../../../../../widgets/pagination";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PaymentMethodTable from "../../table";
import AddPaymentModal from "../../form";

type Props = {
  commonProps: {
    error: any;
    onHide: any;
    loading: any;
    paymentMethodObj: any;
    paymentMethodsData: any;
    modalShow: any;
    apiStatus: any;
    permissions: any;
    totalPages: any;
    filterUpdate: any;
    refreshToggle: any;
    showErrorAlert: any;
    toggleModalShow: any;
    editHandlerById: any;
    newPageRequest: any;
    openAddMealModal: any;
    setShowErrorAlert: any;
    updateInputFilters: any;
    refreshOnDeleteToggle: any;
  };
};

const Mobile = (props: Props) => {
  const { t } = useTranslation();

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );
  return (
    <>
       <BreadcrumbComponent
          routes={
            !["hosteladmin", "hostelwarden"].includes(currentUserRole.shortName)
              ? [
                  { name: t("hmd.mngHostel"), path: "/managehostel" },
                  { name: t("common.siteAdmin"), path: "/siteadmin" },
                  { name: t("hmd.hosteMng"), path: "/siteadmin/hostelmanagement" },
                  { name: t('hmd.invoices&Payments'), path: "/siteadmin/hostelmanagement/allusersinvoice/active/pending" },
                  { name: t('hmd.paymentMethod'), path: '' },
                ]
              : [
                  { name: t("hmd.hosteMng"), path: "/siteadmin/hostelmanagement" },
                  { name: t("hmd.mngHostel"), path: "/managehostel" },
                  { name: t('hmd.invoices&Payments'), path: "/siteadmin/hostelmanagement/allusersinvoice/active/pending" },
                  { name: t('hmd.paymentMethod'), path: '' },
                ]
          }
        />
      <div className="contentarea-wrapper mb-wraper">
          <Container fluid className="mt-3">
            <PageTitle
              pageTitle={t('hmd.paymentMethod')}
              gobacklink="/siteadmin/hostelmanagement/allusersinvoice"
            />
            <Filter
              apiStatus={props.commonProps.apiStatus}
              permissions={props.commonProps.permissions}
              toggleModalShow={props.commonProps.toggleModalShow}
              openAddMealModal={props.commonProps.openAddMealModal}
              updateInputFilters={props.commonProps.updateInputFilters}
            />
            <PaymentMethodTable
              paymentMethodsData={props.commonProps.paymentMethodsData}
              apiStatus={props.commonProps.apiStatus}
              permissions={props.commonProps.permissions}
              editHandlerById={props.commonProps.editHandlerById}
              toggleModalShow={props.commonProps.toggleModalShow}
              refreshdata={props.commonProps.refreshOnDeleteToggle}
            />

            <AddPaymentModal
              onHide={props.commonProps.onHide}
              paymentMethodObj={props.commonProps.paymentMethodObj}
              modalShow={props.commonProps.modalShow}
              updateAddRefresh={props.commonProps.refreshToggle}
              toggleModalShow={props.commonProps.toggleModalShow}
            />
            <BuildPagination
              totalpages={props.commonProps.totalPages}
              getrequestedpage={props.commonProps.newPageRequest}
              activepage={props.commonProps.filterUpdate.pageNumber}
            />
          </Container>
        </div>
    </>
  );
};

export default Mobile;
