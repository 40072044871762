export const handleGlobalError = (errMsg: string, errStatus: number) => {

  let message = "An error occurred";
  let alertBoxColor = "danger"; // Default color for error alerts

  if (errStatus > 0) {
    switch (errStatus) {
    //   case 200:
    //     message = "Hello";
    //     break;
    //   case 201:
    //     message = "Succeccfully created";
    //     break;
      case 400:
        message = errMsg !== "" ? errMsg : "Bad Request";
        break;
      case 401:
        message = errMsg !== "" ? errMsg : "Unauthorized";
        break;
      case 403:
        message = errMsg !== "" ? errMsg : "Forbidden";
        break;
      case 404:
        message = errMsg !== "" ? errMsg : "Resource not found";
        break;
      case 500:
        message = errMsg !== "" ? errMsg : "Internal Server Error";
        break;
      case 502:
        message = errMsg !== "" ? errMsg : "Bad Gateway";
        break;
      default:
        message = errMsg !== "" ? errMsg : "An unexpected error occurred";
        break;
    }
  } else {
    // Handle non-HTTP errors, if any
    message = "An unexpected error occurred";
  }

  return {
    message: message,
    alertBoxColor: alertBoxColor,
    apiErrorStatus: errStatus > 0 ? errStatus : "",
  };
};
