import Filter from "../../filter";
import { Container } from "react-bootstrap";
import BookingRequestTable from "../../table";
import AddBookingRequestModal from "../../form";
// import HeaderTabs from "../../../../../headerTabs";
import PageTitle from "../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import BottomLeftWave from "../../../../../../assets/images/background/bg-bottomleft.svg";
import { useTranslation } from "react-i18next";
import UserBillTable from "../../table";

type Props = {
  commonProps: {
    modalShow: any;
    apiStatus: any;
    refreshToggle: any;
    toggleModalShow: any;
    onHide: any;
    updateInputFilters : any;
    studentBillData: any;
    roomBedAllocations: any;
    paymentMethodsData : any;
    allHostelData: any;    
  };
};
const Browser = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>      
      <div className="contentarea-wrapper mb-5">
        <BreadcrumbComponent
          routes={[
            { name: t("common.home"), path: "/home" },
            { name: t('hmd.myHostel'), path: "/myhostel" },
            { name: t('hmd.invoices&Payments'), path: "" },
          ]}
        />
        <Container fluid className="mt-3">
          <PageTitle
            pageTitle={t('hmd.invoices&Payments')}
            gobacklink="/myhostel"
          />
          {/* <Filter
            toggleModalShow={props.commonProps.toggleModalShow}
            roomBedAllocations ={props.commonProps.roomBedAllocations}
            paymentMethodsData ={props.commonProps.paymentMethodsData}
            updateInputFilters= {props.commonProps.updateInputFilters}
          /> */}

            {/*Filter */}
            <Filter
            updateInputFilters={props.commonProps.updateInputFilters}
            allHostelData={props.commonProps.allHostelData}
            // loading= {props.commonProps.loading}
          />
        
          <UserBillTable
            apiStatus={props.commonProps.apiStatus}
            studentBillData = {props.commonProps.studentBillData}
          />
          
          {/* <AddBookingRequestModal
            onHide={props.commonProps.onHide}
            modalShow={props.commonProps.modalShow}
            hostelData={props.commonProps.hostelData}
            setHostelId={props.commonProps.setHostelId}
            facilityData={props.commonProps.facilityData}
            bookingReqObj={props.commonProps.bookingReqObj}
            updateAddRefresh={props.commonProps.refreshToggle}
            toggleModalShow={props.commonProps.toggleModalShow}
            preferencesData={props.commonProps.preferencesData}
          /> */}

        </Container>
      </div>
      <div className="bottomLeftWave">
        <img src={BottomLeftWave} alt="bottom wave" />
      </div>
    </>
  );
};

export default Browser;
