import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FiltersLoadingBtn } from "../../../../utils/filtersLoading";
import FilterButtonWrapper from "../../../../widgets/filterButtonWrapper";

interface FilterProps {
  updateInputFilters: (filters: any) => void;
  allHostelData: any;
  hostelId: number;
  setHostelId: any;
  apiStatus: any;
  allHostelUsers: any;
}

const Filter: React.FC<FilterProps> = ({
  updateInputFilters,
  allHostelData,
  setHostelId,
  hostelId,
  apiStatus,
  allHostelUsers,
}) => {
  const { t } = useTranslation();
  const { allocationStatus, paymentStatus } = useParams();
  const navigate = useNavigate();
  const [timeoutId, setTimeoutId] = useState<any>(null);

  const initialValues = {
    invoiceNumber: "",
    fullName: "",
    roomNumber: "",
    transactionNumber: "",
    email: "",
    startDate: "",
    endDate: "",
    billStatus: paymentStatus || null,
    roomStatus: allocationStatus || null,
  };

  // Function to filter payments by transaction ID
  const filterPaymentsBytransactionNumber = (
    payments: any[],
    transactionNumber: number
  ) => {
    return payments.filter(
      (payment) => payment.transaction.id === transactionNumber
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (timeoutId) clearTimeout(timeoutId);
      updateInputFilters(values);
    },
    onReset: () => {
      if (timeoutId) clearTimeout(timeoutId);
      formik.setValues(initialValues);
      updateInputFilters({ reset: true });
      setHostelId(0);
    },
  });

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    formik.setFieldValue(name, value); // Update Formik state

    if (timeoutId) clearTimeout(timeoutId); // Clear previous debounce

    const newTimeoutId = setTimeout(() => {
      let updatedFilters: any = { [name]: value };

      // If filtering by transaction ID, filter payments inside transactions
      if (name === "transactionNumber" && value) {
        updatedFilters.filteredPayments = filterPaymentsBytransactionNumber(
          allHostelUsers?.payments || [],
          Number(value)
        );
      }

      updateInputFilters(updatedFilters); // Send updated filter values
    }, 500); // Debounce input updates

    setTimeoutId(newTimeoutId);
  };

  const getCurrentHostel = (e: any) => {
    const value = parseInt(e.target.value);
    setHostelId(value);
  };

  return (
    <div className="filter-wrapper mt-2 me gap-3 umanagement-filter">
      <FilterButtonWrapper>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <Row className="g-2">
            {/* Hostel Name */}
            <Col className="col-auto">
              <label>{t('hmd.selectHostel')}</label>
              <div className="d-flex gap-3">
                <select
                  className="form-select"
                  name="hostelId"
                  onChange={getCurrentHostel}
                  value={hostelId}
                >
                  <option value={0}>{t('common.all')}</option>
                  {allHostelData?.map((hostel: any) => (
                    <option key={hostel.id} value={hostel.id}>
                      {hostel.name}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col className="col-auto">
              <label>{t('hmd.invoiceNumber')}</label>
              <input
                className="form-control"
                id="invoiceNumber"
                name="invoiceNumber"
                type="text"
                placeholder={t('hmd.invoiceNumber')}
                onChange={handleFilterChange}
                value={formik.values.invoiceNumber}
              />
            </Col>
            <Col className="col-auto">
              <label>{t('common.firstName')}/{t('common.lastName')}</label>
              <input
                className="form-control"
                id="fullName"
                name="fullName"
                type="text"
                placeholder={`${t("common.firstName")} / ${t("common.lastName")}`}
                onChange={handleFilterChange}
                value={formik.values.fullName}
              />
            </Col>
            <Col className="col-auto">
              <label>{t('hmd.roomNumber')}</label>
              <input
                className="form-control"
                id="roomNumber"
                name="roomNumber"
                type="text"
                placeholder={t('hmd.roomNumber')}
                onChange={handleFilterChange}
                value={formik.values.roomNumber}
              />
            </Col>
            <Col className="col-auto">
              <label>{t('hmd.transactionNumber')}</label>
              <input
                className="form-control"
                id="transactionNumber"
                name="transactionNumber"
                type="text"
                placeholder={t('hmd.transactionNumber')}
                onChange={handleFilterChange}
                value={formik.values.transactionNumber}
              />
            </Col>
            <Col className="col-auto">
              <label>{t('common.email')}</label>
              <input
                className="form-control"
                id="email"
                name="email"
                type="text"
                placeholder={t('common.email')}
                onChange={handleFilterChange}
                value={formik.values.email}
              />
            </Col>
            <Col className="col-auto">
              <label>{t('common.startDate')}</label>
              <input
                className="form-control"
                id="startDate"
                name="startDate"
                type="date"
                placeholder={t('common.startDate')}
                onChange={handleFilterChange}
                value={formik.values.startDate}
              />
            </Col>
            <Col className="col-auto">
              <label>{t('common.endDate')}</label>
              <input
                className="form-control"
                id="endDate"
                name="endDate"
                type="date"
                placeholder={t('common.endDate')}
                onChange={handleFilterChange}
                value={formik.values.endDate}
              />
            </Col>
            <Col className="col-auto">
              <label>{t('hmd.invoiceStatus')}</label>
              <select
                className="form-select"
                name="billStatus"
                onChange={handleFilterChange}
                value={formik.values.billStatus || ""}
              >
                <option value="">{t('common.all')}</option>
                <option value="pending">{t('hmd.pending')}</option>
                <option value="paid">{t('hmd.paid')}</option>
              </select>
            </Col>
            <Col className="col-auto">
              <label>{t('hmd.allocationStatus')}</label>
              <select
                className="form-select"
                name="roomStatus"
                onChange={handleFilterChange}
                value={formik.values.roomStatus || ""}
              >
                <option value="">{t('common.all')}</option>
                <option value="active">{t('hmd.active')}</option>
                <option value="completed">{t('common.completed')}</option>
              </select>
            </Col>
            <Col className="col-auto">
              <div className="d-flex align-items-end h-100">
                {FiltersLoadingBtn(apiStatus)}
                <Button
                  variant="outline-secondary"
                  type="reset"
                  onClick={formik.handleReset}
                >
                  {t("common.reset")}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </FilterButtonWrapper>

      <div className="site-button-group">
        <Button variant="primary" onClick={() => navigate("paymentmethod")}>
          {t('hmd.paymentMethod')}
        </Button>
      </div>
    </div>
  );
};

export default Filter;
