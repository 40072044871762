import React from "react";
import { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { filterConfig } from "../../../../../utils/filterTimeout";
import { FiltersLoadingBtn } from "../../../../../utils/filtersLoading";
import FilterButtonWrapper from "../../../../../widgets/filterButtonWrapper";

const Filter = ({
  apiStatus,
  permissions,
  preferenceData,
  openAddMealModal,
  updateInputFilters,
}: any) => {
  const initialValues = {
    name: null,
    userPreferences: null,
  };
  const { t } = useTranslation();
  const [timeoutId, setTimeoutId] = useState<any>(null);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (timeoutId) clearTimeout(timeoutId); // Clear previous timeout, if any
      let newRequest = {
        name: values.name,
        userPreferences: values.userPreferences,
      };
      updateInputFilters(newRequest);
    },
    onReset: () => {
      if (timeoutId) clearTimeout(timeoutId); // Clear previous timeout, if any
      formik.setValues(initialValues);
      updateInputFilters({ reset: true });
    },
  });

  // Event handler for filter input change with debounce
  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    formik.handleChange(event);
    // Update formik values

    if (timeoutId) clearTimeout(timeoutId); // Clear previous timeout, if any

    // Set a new timeout to trigger updatefilters after a delay
    const newTimeoutId = setTimeout(() => {
      const { name, value } = event.target; // Destructure name and value from the event target

      // Create the new request based on the formik values and the current input change
      let newRequest = {
        name: name === "name" ? value || null : formik.values.name || null,
        userPreferences:
          name === "userPreferences"
            ? value || null
            : formik.values.userPreferences || null,
      };

      updateInputFilters(newRequest);
    }, filterConfig.timeoutNumber); // Adjust the delay (in milliseconds) as per your needs

    setTimeoutId(newTimeoutId); // Update the timeout ID in state
  };

  return (
    <React.Fragment>
      <div className="filter-wrapper mt-2 me gap-3 umanagement-filter">
      <FilterButtonWrapper> 
          <form></form>
      </FilterButtonWrapper>
        {permissions.hostelAdmin.meal.canAdd && (
          <div className="site-button-group">
            <Button variant="primary" onClick={openAddMealModal}>
              {t('hmd.addPaymentMethod')}
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Filter;
