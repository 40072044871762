
import React from "react";
import ModalForm from "../../form";
import ManageFilter from "../../filter";
import { Container } from "react-bootstrap";
import DraftVersionTable from "../../table";
import { useNavigate } from "react-router-dom";
import HeaderTabs from "../../../../../headerTabs";
import PageTitle from "../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import CustomButton from "../../../../../../widgets/formInputFields/buttons";
import { useTranslation } from "react-i18next";

type Props = {
  commonProps: {
    urlArg: any;
    onHide: any;
    apiStatus: any;
    modalShow: any;
    timeslots: any;
    courseDates: any;
    requestCount:any;
    filteredTime: any;
    modalFormData: any;
    refreshToggle: any;
    selectedCourse: any;
    availableRooms: any;
    handleDragEnter:any;
    toggleModalShow: any;
    requestTimeSlot: any;
    getModalFormData: any;
    sortedCategories: any;
    setCoursesStatus: any;
    setSelectedCourse: any;
    updateCourseDates: any;
    handleMonthFilter: any;
    changeRequestData: any;
    changeFilterStatus: any;
    updateFacultyStatus: any;
    updateTimetableDates: any;
    setHandleMonthFilter: any;
    setChangeFilterStatus: any;
  };
};

const Browser = (props: Props) => {
  const navigate = useNavigate()
  const {t} =  useTranslation()
  
  return (
    <React.Fragment>
      <div className="contentarea-wrapper mb-5">
        <BreadcrumbComponent
          routes={[
            { name: t('common.siteAdmin'), path: "/siteadmin" },
            { name: t('trp.timeTableMng'), path: "/timetable" },
            { name:  t('trp.draftVersion'), path: "" },
          ]}
        />
        <Container fluid className="mt-3">
          <PageTitle
            pageTitle={`${ t('trp.draftVersion')}: <span>${props.commonProps.urlArg.prg}</span>`}
            gobacklink="/timetable"
          />
          <ManageFilter
            ids={props.commonProps.urlArg}
            courseDates={props.commonProps.courseDates}
            workloadCourses={props.commonProps.sortedCategories}
            setCoursesStatus={props.commonProps.setCoursesStatus}
            updateCourseDates={props.commonProps.updateCourseDates}
            changeFilterStatus={props.commonProps.changeFilterStatus}
            updateFacultyStatus={props.commonProps.updateFacultyStatus}
            setHandleMonthFilter={props.commonProps.setHandleMonthFilter}
            setChangeFilterStatus={props.commonProps.setChangeFilterStatus}
            selectedCourse={props.commonProps.selectedCourse}
            requestCount={props.commonProps.requestCount}
            setSelectedCourse={props.commonProps.setSelectedCourse}
          />
          <ModalForm
            urlArg={props.commonProps.urlArg}
            onHide={props.commonProps.onHide}
            modalShow={props.commonProps.modalShow}
            filteredTime={props.commonProps.filteredTime}
            modalFormData={props.commonProps.modalFormData}
            availableRooms={props.commonProps.availableRooms}
            toggleModalShow={props.commonProps.toggleModalShow}
            requestTimeSlot={props.commonProps.requestTimeSlot}
            changeRequestData={props.commonProps.changeRequestData}
          />
          <DraftVersionTable
            SlotData={props.commonProps.timeslots}
            apiStatus={props.commonProps.apiStatus}
            handleDragEnter={props.commonProps.handleDragEnter}
            courseDates={props.commonProps.courseDates}
            toggleModalShow={props.commonProps.toggleModalShow}
            getModalFormData={props.commonProps.getModalFormData}
            handleMonthFilter={props.commonProps.handleMonthFilter}
            setHandleMonthFilter={props.commonProps.setHandleMonthFilter}
            updateTimetableDates={props.commonProps.updateTimetableDates}
            setChangeFilterStatus={props.commonProps.setChangeFilterStatus}
          />
        </Container>
        <div className="modal-buttons">
          <CustomButton
            type="submit"
            btnText="Submit Changes"
            variant="primary"
            // disabled={isSubmitting}
          />
          <CustomButton
            type="reset"
            btnText="Reset"
            variant="outline-secondary"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Browser;

