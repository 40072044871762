import { jsPDF } from "jspdf";
import { pagination } from "../../utils/pagination";
import { getData } from "../../adapters/hostelservices";
import { capitalizeFirstWords } from "../titleCapitalize/capitalizeFirstWords";
import autoTable from "jspdf-autotable";
import pdfLogobg from "../../assets/images/pdfLogobg.png";
import pdfLogo from "../../assets/images/pdfBallistic_Logo_Blue.png";
import pdfFooter from "../../assets/images/pdfFooter.png";
// import pdfTableHeader from "../../assets/images/pdfFooter.png"

const invoiceDownload = async (billId: number, currentInstitute: number) => {
  try {
    const res = await getData(
      `${currentInstitute}/hostel/bill-details/${billId}`,
      {
        pageNumber: 0,
        pageSize: pagination.PERPAGE,
      }
    );

    if (res.status === 200 && res.data) {
      createDocAndSave(res.data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export default invoiceDownload;

const createDocAndSave = (studentBillData: any) => {
  const doc = new jsPDF();

  // Add image to PDF
  doc.addImage(pdfLogobg, "PNG", 150, 10, 80, 28); // (image, format, x, y, width, height)
  doc.addImage(pdfLogo, "PNG", 150, 10, 50, 28); // (image, format, x, y, width, height)

  // Header: INVOICE Title
  doc.setFont("helvetica", "bold").setFontSize(40).text("INVOICE", 15, 25);

  // Pay Invoice Button (Styled as Blue Box)
  doc.setFillColor(0, 60, 255);
  doc.rect(15, 27, 50, 7, "F");
  doc.setTextColor(255, 255, 255);
  doc.setFont("helvetica", "bold").setFontSize(10).text("Invoice No:", 18, 32)
  doc.setFontSize(10).text(`${studentBillData?.invoiceNumber}`, 40, 32);
  doc.setTextColor(0, 0, 0);

  // Function to add field labels and values
  const addField = (label: any, value: any, x: any, y: any) => {
    doc.setFont("helvetica", "bold").text(`${label}:`, x, y);
    doc
      .setFont("helvetica", "normal")
      .setFontSize(9)
      .text(`${value || "N/A"}`, x + 35, y);
  };

  // **Student Information**
  doc
    .setFont("helvetica", "bold")
    .setFontSize(14)
    .setTextColor(15, 128, 239)
    .text("Student Details", 15, 45);
  doc.setFont("helvetica", "normal").setFontSize(9).setTextColor(0, 0, 0); // Reset to normal

  // Add student details fields
  addField("Full Name", studentBillData?.fullName, 15, 57);
  addField("Hostel Name", studentBillData?.hostelName, 15, 64);
  addField("Email", studentBillData?.email, 15, 71);
  addField("Room No", studentBillData?.roomNumber, 15, 78);
  addField("Program Name", studentBillData?.programName, 15, 85);
  addField("Bed No", studentBillData?.bedNumber, 15, 92);
  addField("Program Code", studentBillData?.programCode, 15, 99);

  // **Invoice Information**
  doc
    .setFont("helvetica", "bold")
    .setFontSize(14)
    .setTextColor(15, 128, 239)
    .text("Invoice Details", 115, 45);
  doc.setFont("helvetica", "normal").setFontSize(9).setTextColor(0, 0, 0); // Reset to normal

  // Add invoice details fields
  addField("Invoice Number", studentBillData?.userBillId, 115, 57);
  addField(
    "Billing Period",
    `${studentBillData?.validFrom} to ${studentBillData?.validTo}`,
    115,
    64
  );
  addField("Due Date", studentBillData?.dueDate, 115, 71);

  // for status of bill
  const label = "Status:"; // Label text
  const value = capitalizeFirstWords(studentBillData?.billstatus); // Field value
  const x = 115,
    y = 78; // Position
  const padding = 4; // Padding around text

  const bgColor =
    studentBillData?.billstatus === "paid" ? [194, 244, 194] : [255, 235, 204];
  const textColor =
    studentBillData?.billstatus === "paid" ? [0, 128, 0] : [250, 115, 20];

  // Set font to bold for the label
  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);

  // Measure text width
  const labelWidth = doc.getTextWidth(label);
  const valueWidth = doc.getTextWidth(value);
  const textHeight = 5; // Adjusted for font size 9

  // Print the "Status:" label in black
  doc.setTextColor(0, 0, 0);
  doc.text(label, x, y);

  // Reset font to normal for the value
  doc.setFont("helvetica", "normal");

  // Draw background rectangle for the value
  const valueX = x + labelWidth + 24; // Position value after label
  doc.setFillColor(...bgColor);
  doc.roundedRect(
    valueX - padding,
    y - padding,
    valueWidth + 2 * padding,
    textHeight + 2,
    2,
    2,
    "F"
  );

  // Set text color for the status value
  doc.setTextColor(...textColor);
  doc.text(value, valueX, y);

  doc.setTextColor(0, 0, 0); // Resets text color to black

  var currentX = 115;
  var currentY = 96;

  // Charges Details Table
  doc
    .setFont("helvetica", "bold")
    .setFontSize(14)
    .text("Charges Summary", 15, currentY + 15);

  const tableBody = [];

  if (studentBillData?.serviceCharge) {
    tableBody.push(["Service Charge", `${studentBillData.serviceCharge} INR`]);
  }

  if (studentBillData?.discount > 0) {
    tableBody.push([
      `Discount ${studentBillData.discount}%`,
      `-${studentBillData.discountAmount} INR`,
    ]);
  }

  if (
    studentBillData?.discount > 0 &&
    studentBillData?.totalServiceChargeAfterDiscount
  ) {
    tableBody.push([
      "Total Service Charge",
      `${studentBillData.totalServiceChargeAfterDiscount} INR`,
    ]);
  }

  if (studentBillData?.lateFeeAmount && studentBillData?.lateFeeCharge) {
    tableBody.push([
      `Late Fee Charge (${studentBillData.lateFeeAmount} INR per day)`,
      `${studentBillData.lateFeeCharge} INR`,
    ]);
  }

  if (studentBillData?.amount) {
    tableBody.push(["Total Amount ", `${studentBillData.amount} INR`]);
  }

  if (tableBody.length > 0) {
    const startY = currentY + 20;
    const tableWidth = 179; // Adjust as needed
    const rowHeight = 10; // Header row height

 // Apply solid fill color
doc.setFillColor(0, 60, 255);
doc.rect(15, startY, tableWidth, rowHeight, "F"); // Single filled rectangle
    autoTable(doc, {
      startY: currentY + 20,
      head: [["Description", "Amount"]],
      body: tableBody,
      theme: "striped",
      headStyles: {
        fillColor: false, // Disable default color so the gradient is visible
        textColor: [255, 255, 255], // White Header Text
        fontSize: 12,
        fontStyle: "bold",
      },
      bodyStyles: {
        fontSize: 10,
        cellPadding: 2,
      },
      alternateRowStyles: {
        fillColor: [240, 248, 255], // Light blue alternate row
      },
      styles: {
        lineColor: [220, 220, 220], // Subtle borders
      },
      didParseCell: (data: any) => {
        if (data.row.raw[0] === "Total Amount ") {
          data.cell.styles.fontStyle = "bold"; // Make "Total Amount" bold
          data.cell.styles.textColor = [0, 0, 0]; // Ensure black text
        }
        if (data.column.index === 1 && data.row.raw[0] === "Total Amount ") {
          data.cell.styles.fontStyle = "bold"; // Make the Amount bold
          data.cell.styles.textColor = [0, 0, 0]; // Ensure black text
        }
      },
    });
  }

  let finalY = (doc as any).lastAutoTable?.finalY || currentY + 20;

  if (
    studentBillData?.payments?.length > 0 &&
    studentBillData?.payments?.some(
      (payment: any) => payment.transaction.transactionStatusType === "success"
    )
  ) {
    doc
      .setFont("helvetica", "bold")
      .setFontSize(14)
      .text("Payment Summary", 15, finalY + 15);

    const startY = finalY + 20;
    const tableWidth = 179; // Adjust as needed
    const rowHeight = 10; // Header row height

// Apply solid fill color
doc.setFillColor(0, 60, 255);
doc.rect(15, startY, tableWidth, rowHeight, "F"); // Single filled rectangle
    // Now render the table
    autoTable(doc, {
      startY,
      head: [
        [
          "S.No",
          "Created By",
          "Paid By",
          "Note",
          "Transaction Id",
          "Date",
          "Amount",
        ],
      ],
      body: [
        ...studentBillData.payments
          .filter(
            (element: any) =>
              element.transaction.transactionStatusType === "success"
          )
          .map((item: any, index: number) => [
            `${index + 1}`,
            item.createdBy,
            capitalizeFirstWords(item?.transaction?.paidBy || ""),
            capitalizeFirstWords(item?.transaction?.transactionNote || ""),
            item?.transaction?.id || "N/A",
            item.paymentDate || "N/A",
            `${item?.transaction?.amount || "0.00"} INR`,
          ]),
        studentBillData?.totalPaidAmount && [
          "",
          "",
          "",
          "",
          "",
          "Paid Amount ",
          `${studentBillData?.totalPaidAmount} INR`,
        ],
      ],
      theme: "striped",
      headStyles: {
        fillColor: false, // Disable default color so the gradient is visible
        textColor: [255, 255, 255], // White Header Text
        fontSize: 12,
        fontStyle: "bold",
      },
      didParseCell: (data: any) => {
        if (data.row.raw[0] === "" && data.row.raw[5] === "Paid Amount ") { 
          // Check if it's the last row containing "Paid Amount "
          if (data.column.index === 5 || data.column.index === 6) { 
            // Column 5 contains "Paid Amount ", Column 6 contains the amount value
            data.cell.styles.fontStyle = "bold"; 
            data.cell.styles.textColor = [0, 0, 0]; // Ensure black text
          }
        }
      },
      bodyStyles: {
        fontSize: 10,
        cellPadding: 2,
      },
      alternateRowStyles: {
        fillColor: [240, 248, 255], // Light blue alternate row
      },
      styles: {
        lineColor: [220, 220, 220], // Subtle borders
      },
    });

    if (studentBillData?.billstatus !== "paid") {

      doc.setFillColor(0, 60, 255);
      doc.rect(15, startY + 60, tableWidth, rowHeight, "F"); // Single filled rectangle
    
      doc.setTextColor(255, 255, 255); // Set text color to white for contrast

      if (studentBillData?.waiveOffAmount) {
        doc
          .setFont("helvetica", "bold")
          .setFontSize(14)
          .text("Waive Off Amount:", 18, startY + 67);
        doc
          .setFontSize(14)
          .text(`${studentBillData?.waiveOffAmount} INR`, 65, startY + 67);
        doc
          .setFont("helvetica", "bold")
          .setFontSize(14)
          .text("Amount After Waive Off:", 100, startY + 67);
        doc
          .setFontSize(14)
          .text(
            `${studentBillData?.payableAmountAfterWaiveOff} INR`,
            160,
            startY + 67
          );
      } else if (!studentBillData?.waiveOffAmount) {
        doc
          .setFont("helvetica", "bold")
          .setFontSize(14)
          .text("Due Amount:", 18, startY + 67);
        doc
          .setFontSize(14)
          .text(` ${studentBillData?.amountDue} INR`, 55, startY + 67);
      }
    }
  }

  doc.setTextColor(0,0,0)

  // Terms & Conditions
  doc
    .setFont("helvetica", "bold")
    .setFontSize(12)
    .text("Terms & Conditions", 20, 265);

  // Set paragraph text
  doc
    .setFont("helvetica", "normal")
    .setFontSize(9)
    .text(
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. " +
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      21,
      270,
      { maxWidth: 95 }
    );

  // Signature
  doc.setFont("helvetica", "bold").setFontSize(12).text("Signature", 165, 280);

  // Footer
  doc.addImage(pdfFooter, "svg", 0, 288, 240, 10); // (image, format, x, y, width, height)

  const pdfBlobUrl = doc.output("bloburl");
  window.open(pdfBlobUrl, "_blank");
};
