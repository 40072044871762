import moment from "moment";
import 'moment-timezone';

// timestamp to 01/02/2024 this format date converter === >>
export const convertTimestampToDate = (value) => {
  if (value !== undefined && value !== "" && value !== "0") {
    const timestamp = parseInt(value);
    const date = new Date(timestamp * 1000);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  } else {
    return "--/--/----";
  }
};

// January 4, 2024 format date converter === >>
export const formattedDate = (date) => {
  const getFormatedDate = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return getFormatedDate;
};

// converted date time UTC to IST
export const formattedDateTime = (date) => {
  const utcDate = moment.utc(date);
  const istDate = utcDate.tz('Asia/Kolkata').format('MMMM DD, YYYY [at] h:mm A');
  // console.log(istDate, 'istDate')
  return istDate;
};


// =========================================================================
export const formattedDateNew = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(date);
}

// date Str to timestamp === >>
const dateStrToTimmestamp = (date) => {
  const dateStr = date;
  const timestamp = new Date(dateStr).getTime() / 1000;
  return timestamp
}

// 3 Days ago format date converter === >>
export const timestampToDaysAgoConverter = (date) => {
  const timestamp = dateStrToTimmestamp(date);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const differenceInSeconds = currentTimestamp - timestamp;
  const differenceInDays = Math.floor(differenceInSeconds / (24 * 60 * 60));
  const formattedDate = `${differenceInDays} days ago`;
  return formattedDate;
};

//  convert the date format from "yyyy-MM-dd" to "dd-MM-yyyy" === >>
export const dateConverterToDYM = (date) => {
  // console.log(date)
  const originalDate = date;
  const formattedDate = moment(originalDate).format("DD-MM-YYYY");

  return (formattedDate);
}
//  convert the date String format from "yyyy-MM-dd" to "dd-MM-yyyy" === >>
export const formatDateToDDMMYYYY = (dateString) => {
  if (!dateString) return '';

  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};


//  convert the date format from "dd-MM-yyyy"  to "yyyy-MM-dd" 
export const dateConverterToYMD = (date) => {
  const originalDate = date;
  const formattedDate = moment(originalDate, "DD-MM-YYYY").format("YYYY-MM-DD");
  return formattedDate;
};

// Converts a date string from "day/month/year" format to  weekday, month, day, year, hour, minute, second, and time zone name.

export const formatDateWithDetails = (dateString) => {
  // Split the date string into day, month, and year parts
  const [day, month, year] = dateString.split('/');
  // Create a Date object with the parsed parts (month - 1 because months are zero-indexed)
  const date = new Date(year, month - 1, day);
  // Format the date string in the desired format
  const formattedDate = date.toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
  });

  return formattedDate;
};

// convert Time To Seconds from 12:54:00
export  const convertTimeToSeconds = (timeString) => {
  // Split the time string into parts (hours, minutes, seconds)
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  // Calculate total seconds
  return (hours * 3600) + (minutes * 60) + seconds;
};


// convert date format (YYYY-MM-DD)
// export const formatDateWithTime = (value) => {
//   const date = new Date(value);
//   // Format the date and time
//   const formattedDate = date.toLocaleDateString("en-CA"); 
//   const formattedTime = date.toLocaleTimeString("en-US", {
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: true,
//   }); // 12-hour format with AM/PM
//   return `${formattedDate}, ${formattedTime}`;
// }

export const formatDateWithTime = (value: any) => {
  if (!value) return "-"; // Handle null or undefined values
  const date = new Date(value);

  if (isNaN(date.getTime())) return "N/A"; // Handle invalid dates

  const formattedDate = date.toLocaleDateString("en-CA"); // ISO 8601 date format (YYYY-MM-DD)
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }); // 12-hour format with AM/PM
  return `${formatDateToDDMMYYYY(formattedDate)}, ${formattedTime}`;
};

export const getDayFromDate = (dateString) => {
  const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  const date = new Date(dateString);
  return days[date.getDay()];
};

// convert date format (YYYY-MM-DD)
export const formatDate = (value: any) => {
  if (!value) return "-"; // Handle null or undefined values
  const date = new Date(value);

  if (isNaN(date.getTime())) return "N/A"; // Handle invalid dates

  const formattedDate = date.toLocaleDateString("en-CA"); // ISO 8601 date format (YYYY-MM-DD)
  // const formattedTime = date.toLocaleTimeString("en-US", {
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   hour12: true,
  // }); // 12-hour format with AM/PM
  return `${formattedDate}`;
};