import React from 'react'
import { Outlet, Route } from 'react-router-dom'
import Payments from '../../../pages/hostelManagement/studentHostelDashboard/payment'
// import UserPaymentDetails from '../../../pages/hostelManagement/studentHostelDashboard/payment/paymentDetails'

const StudentVisitorRequest = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/studentVisitorRequest'))
const StudentServiceRequest = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/studentServiceRequest'))
const StudentBookingRequest = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/studentBookingRequest'))
const MyWallet = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/payment/myWallet'))
const HostelFacility = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/hostelFacility'))
const StudentMessMenu = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/studentMessMenu'))
const StudentHostelDashboard = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard'))
const Checkout = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/payment/checkout'))
const UserPaymentDetails = React.lazy(()=> import('../../../pages/hostelManagement/studentHostelDashboard/payment/paymentDetails'))
const StudentHostelManagementRoutes = () => (
    <Route key='myhostel' path='/myhostel' element={<Outlet />} >
        <Route index element={<StudentHostelDashboard />} />
        <Route key='facilities' path='facilities/:hostelId' element={<HostelFacility/>}/>,
        <Route key='messmenu' path='messmenu' element={<StudentMessMenu/>}/>,
        <Route key='servicerequest' path='servicerequest' element={<StudentServiceRequest />} />,
        <Route key='bookingrequest' path='bookingrequest' element={<StudentBookingRequest />} />,
        <Route key='visitorrequest' path='visitorrequest' element={<StudentVisitorRequest />} />,
        <Route key='hostelpayment' path='hostelpayment' element={<Payments />} />,
        <Route key='mywallet' path='hostelpayment/mywallet' element={<MyWallet />} />,
        <Route key='userpaymentdetails' path='hostelpayment/userpaymentdetails/:id' element={<UserPaymentDetails />} />,
        <Route key='checkout' path='hostelpayment/userpaymentdetails/:id/checkout/:id' element={<Checkout />} />,
    </Route>  
)

export default StudentHostelManagementRoutes