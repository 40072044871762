import "./style.scss";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import SimpleMailConfigration from "./smpt";
import { useTranslation } from "react-i18next";
import PageTitle from "../../widgets/pageTitle";
import { Container, Tabs } from "react-bootstrap";
import MailTempelateConfig from "./mailConfigration";
import BreadcrumbComponent from "../../widgets/breadcrumb";
import {
  BackgroundWaveBottomRight,
  BackgroundWaveTopLeft,
} from "../../widgets/backgroundElements";
import LearningConfiguration from "./learning";
import HostelConfiguration from "./hostel";

const MailTempelateConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("mail");

  return (
    <React.Fragment>
      <div className="contentarea-wrapper mb-5">
        <BreadcrumbComponent
          routes={[
            { name: t("common.home"), path: "/home" },
            {
              name: t("userProfile.siteConfig"),
              path: "",
            },
          ]}
        />
        <Container fluid className="mt-3">
          <PageTitle
            pageTitle={`${t("userProfile.siteConfig")}: ${
              activeTab === "mail"
                ? "<span>Site Templates</span>"
                : activeTab === "smtp"
                ? "<span>SMTP</span>"
                : activeTab === "learning"
                ? "<span>Learning Email</span>"
                : "<span>Hostel Email</span>"
            }`}
            className="mt-2"
            gobacklink="/home"
          />
          <Tabs
            id="uncontrolled-tab-example"
            className="tabStep-indicator"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k || "mail")}
          >
            
            <Tab eventKey="smtp" title="SMTP">
              <SimpleMailConfigration />
            </Tab>
            <Tab eventKey="mail" title="Site Templates">
              <MailTempelateConfig />
            </Tab>
            <Tab eventKey="learning" title="Learning Email">
              <LearningConfiguration />
            </Tab>
            <Tab eventKey="hostel" title="Hostel Email">
              <HostelConfiguration />
            </Tab>
          </Tabs>
        </Container>
      </div>
      <BackgroundWaveTopLeft />
      <BackgroundWaveBottomRight />
    </React.Fragment>
  );
};

export default MailTempelateConfiguration;
