import "./style.scss";
import View from "./view";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AdminRawData } from "./rawData";
import { useSelector } from "react-redux";
// import config from "../../../utils/config";
import PriceRequestModal from "../../../widgets/priceRequestModal/PriceRequestModal";

const SiteAdminHome = () => {
  const x = localStorage.getItem("userInfo");
  const [showModals, setShowModal] = useState(false);

  const permissions = useSelector(
    (state: any) => state.userAuthorities.permissions
  );

  // Get the raw data from AdminRawData
  const rawData = AdminRawData();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const renderComponent = (item: any, index: number) => {
    let componentEnabled = true;
    if (item.component === "user" && !permissions.user.canView) {
      componentEnabled = false;
    } else if (item.component === "program" && !permissions.program.canView) {
      componentEnabled = false;
    } else if (
      item.component === "enrolment" &&
      !permissions.enrolment.program.canView
    ) {
      componentEnabled = false;
    } else if (
      item.component === "institute" &&
      !permissions.institute.canView
    ) {
      componentEnabled = false;
    } else if (item.component === "copo" && !permissions.COPO.canView) {
      componentEnabled = false;
    } else if (
      item.component === "hostel" &&
      !permissions.hostelAdmin.dashboard.canView
    ) {
      componentEnabled = false;
    }

    item.enabled = componentEnabled;
    // item.link = componentEnabled ? "#" : item.link;

    return (
      <div key={index} className={`box ${item.boxclassname}`}>
        <Link
          to={componentEnabled ? item.link : "#"}
          className={`default-item ${item.classname}`}
          style={
            componentEnabled
              ? {}
              : { opacity: 0.4, boxShadow: "none", cursor: "not-allowed" }
          }
        >
          <h4
            className="card-title"
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
          <img src={item.image} alt={item.title} className="img-fluid" />
        </Link>
      </div>
    );
  };

  return (
    <>
      <View adminRawData={rawData} renderComponent={renderComponent} />
      <PriceRequestModal show={showModals} handleClose={handleCloseModal} />
    </>
  );
};

export default SiteAdminHome;
