import { useCallback, useEffect, useState } from "react";
import { getData, postData } from "../../../adapters/microservices";
import { TConfigItem } from "../typescript";
import View from "./view";

type Props = {};

const LearningConfiguration = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiStatus, setApiStatus] = useState<string>("");
  const [tabTitles, setTabTitles] = useState<string[]>([]);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [initialSubject, setInitialSubject] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<string>("Course Enrolment Mail");
  const [learningMailConfig, setLearningMailConfig] = useState<TConfigItem[]>([]);
  const [getConfigApiStatus, setGetConfigApiStatus] = useState<string>("");
  const [initialDescription, setInitialDescription] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("en");

  //localhost:8081/learning-service/api/v1/config
  https: useEffect(() => {
    let endPoint = `/config?languageCode=${selectedValue}`;
    setGetConfigApiStatus("started");
    getData(endPoint, {}).then((res: any) => {
      if (res.data !== "" && res.status === 200) {
        setLearningMailConfig(res.data);
        const tabTitlesList = [
          ...new Set(res.data.map((item: TConfigItem) => item.tabTitle)),
        ];
        setTabTitles(tabTitlesList);
      }
      setGetConfigApiStatus("finished");
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedTab !== "" && learningMailConfig.length > 0) {
        setLoading(true);
        let selectedConfigList = learningMailConfig
          .filter((item) => item.tabTitle === selectedTab)
          .map((el) => el.config);

        try {
          setApiStatus("started");
          let endPoint = `/config?languageCode=${selectedValue}`;
          const res = await postData(endPoint, selectedConfigList);
          if (res.data !== "" && res.status === 200) {
            let subject = "";
            let description = "";
            res.data.forEach((item: any) => {
              const config = item.configKey.substring(
                item.configKey.lastIndexOf("_") + 1
              );
              if (config === "subject") {
                subject = item.configValue;
              } else {
                description = item.configValue;
              }
            });
            setInitialSubject(subject);
            setInitialDescription(description);
            setLoading(false);
          }
          setApiStatus("finished");
        } catch (error) {
          console.error("Error fetching initial values:", error);
          setApiStatus("finished");
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [selectedTab, refreshData, learningMailConfig, selectedValue]);

  const toggleRefresh = () => {
    setRefreshData(!refreshData);
  };

  const languageSelectHandler = useCallback((e: { target: { value: any } }) => {
    const getLanguageCode = e.target.value;
    setSelectedValue(getLanguageCode);
  }, []);
  // console.log(selectedTab)

  return (
    <View
      loading={loading}
      apiStatus={apiStatus}
      tabTitles={tabTitles}
      selectedValue={selectedValue}
      mailConfigData={learningMailConfig}
      initialSubject={initialSubject}
      toggleRefresh={toggleRefresh}
      setSelectedTab={setSelectedTab}
      getConfigApiStatus={getConfigApiStatus}
      initialDescription={initialDescription}
      languageSelectHandler={languageSelectHandler}
    />
  );
};

export default LearningConfiguration;
