import React from "react";
import { Container } from "react-bootstrap";
import WorkLoadComp from "../../workLoadComp";
import PageTitle from "../../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";

type Props = {
  commonProps: {
    apiStatus: any;
    timeSlotList: any;
    workloadData: any;
    currentInstitute: any;
  };
};

const Mobile = (props: Props) => {
  return (
    <React.Fragment>
      <BreadcrumbComponent
        routes={[
          { name: "Site Administration", path: "/siteadmin" },
          { name: "Timetable Management", path: "/timetable" },
          { name: "Faculty Work Load", path: "/workload" },
          { name: "Manage Faculty Work Load", path: "" },
        ]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <Container fluid className="mt-3">
          <PageTitle
            pageTitle="Manage Faculty Work Load"
            gobacklink="/workload"
          />
          <WorkLoadComp
            apiStatus={props.commonProps.apiStatus}
            timeSlotList={props.commonProps.timeSlotList}
            workloadData={props.commonProps.workloadData}
            currentInstitute={props.commonProps.currentInstitute}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Mobile;
