import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WaiveOfModalForm from "../../waiveOffModal";
import MakePaymentModal from "../../makePamentModal";
import UserInvoiceDetails from "../../userInvoiceDetails";
import PageTitle from "../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import RouterLadyLoader from "../../../../../../globals/globalLazyLoader/routerLadyLoader";

type Props = {
  commonProps: {
    onHide: any;
    onHides: any;
    loading: any;
    modalShow: any;
    apiStatus: any;
    refreshToggle: any;
    waiveModalShow: any;
    studentBillData: any;
    toggleModalShow: any;
    updateInputFilters: any;
    paymentMethodsData: any;
    waiveToggleModalShow: any;
    offlinePaymentResponse: any;
  };
};

const Mobile = (props: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  return (
    <>
      <BreadcrumbComponent
        routes={
          !["hosteladmin", "hostelwarden"].includes(currentUserRole.shortName)
            ? [
                { name: t("common.home"), path: "/home" },
                { name: t("common.siteAdmin"), path: "/siteadmin" },
                {
                  name: t("hmd.hosteMng"),
                  path: "/siteadmin/hostelmanagement",
                },
                {
                  name: t("hmd.invoices&Payments"),
                  path: "/siteadmin/hostelmanagement/allusersinvoice/active/pending",
                },
                { name: t("hmd.payInvoice"), path: "" },
              ]
            : [
                {
                  name: t("hmd.hosteMng"),
                  path: "/siteadmin/hostelmanagement",
                },
                { name: t("hmd.mngHostel"), path: "/managehostel" },
                {
                  name: t("hmd.invoices&Payments"),
                  path: "/siteadmin/hostelmanagement/allusersinvoice/active/pending",
                },
                { name: t("hmd.payInvoice"), path: "" },
              ]
        }
      />
      <div className="contentarea-wrapper mb-wraper">
        <Container fluid className="mt-3">
          <PageTitle
            pageTitle={`${t("hmd.invoiceNumber")}: <span> ${props.commonProps.studentBillData?.invoiceNumber} </span>`}
            gobacklink="/siteadmin/hostelmanagement/allusersinvoice/active/pending"
          />

          {props.commonProps.loading ? (
            <RouterLadyLoader status={true} />
          ) : (
            <UserInvoiceDetails
              studentBillData={props.commonProps.studentBillData}
              toggleModalShow={props.commonProps.toggleModalShow}
              waiveToggleModalShow={props.commonProps.waiveToggleModalShow}
            />
          )}
        </Container>
        <MakePaymentModal
          onHide={props.commonProps.onHide}
          modalShow={props.commonProps.modalShow}
          refreshToggle={props.commonProps.refreshToggle}
          toggleModalShow={props.commonProps.toggleModalShow}
        />
        <WaiveOfModalForm
          onHide={props.commonProps.onHides}
          modalShow={props.commonProps.waiveModalShow}
          refreshToggle={props.commonProps.refreshToggle}
          studentBillData={props.commonProps.studentBillData}
          toggleModalShow={props.commonProps.waiveToggleModalShow}
        />
      </div>
    </>
  );
};

export default Mobile;
