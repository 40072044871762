import Filter from "../../filter";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UsersInvoiceTable from "../../allusersInvoiceTable";
import PageTitle from "../../../../../../widgets/pageTitle";
import BuildPagination from "../../../../../../widgets/pagination";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import BottomLeftWave from "../../../../../../assets/images/background/bg-bottomleft.svg";

type Props = {
  commonProps: {
    loading: any;
    hostelId: any;
    totalPages: any;
    setHostelId: any;
    filterUpdate: any;
    allHostelData: any;
    allHostelUsers: any;
    newPageRequest: any;
    updateInputFilters:any;
  };
};

const Browser = (props: Props) => {
  const {t} = useTranslation();

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  return (
    <>      
      <div className="contentarea-wrapper mb-5">
        <BreadcrumbComponent
          routes={
            !["hosteladmin", "hostelwarden"].includes(currentUserRole.shortName)
              ? [
                { name: t("common.home"), path: "/home" },
                { name: t("common.siteAdmin"), path: "/siteadmin" },
                { name: t("hmd.hosteMng"), path: "/siteadmin/hostelmanagement" },
                { name: t('hmd.invoices&Payments'), path: "" },
              ]
              : [
                { name: t("hmd.hosteMng"), path: "/siteadmin/hostelmanagement" },
                { name: t("hmd.mngHostel"), path: "/siteadmin/hostelmanagement/managehostel" },
                { name: t('hmd.invoices&Payments'), path: "" },
              ]
          }
        />
        <Container fluid className="mt-3">
          <PageTitle
            pageTitle={t('hmd.invoices&Payments')}
            gobacklink="/siteadmin/hostelmanagement"
          />
          {/*Filter */}
          <Filter
            apiStatus= {props.commonProps.loading}
            hostelId= {props.commonProps.hostelId}
            setHostelId= {props.commonProps.setHostelId}
            allHostelData={props.commonProps.allHostelData}
            allHostelUsers={props.commonProps.allHostelUsers}
            updateInputFilters={props.commonProps.updateInputFilters}
          />

          {/* user Invoice table  */}
          <UsersInvoiceTable
            loading = {props.commonProps.loading}
            allHostelUsers={props.commonProps.allHostelUsers}
          />

          <BuildPagination
            totalpages={props.commonProps.totalPages}
            getrequestedpage={props.commonProps.newPageRequest}
            activepage={props.commonProps.filterUpdate.pageNumber}
          />
        </Container>
      </div>
      <div className="bottomLeftWave">
        <img src={BottomLeftWave} alt="bottom wave" />
      </div>
    </>
  );
};

export default Browser;
