import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import i18n from "../../../../languages";
import { Field, Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import TimerAlertBox from "../../../../widgets/alert/timerAlert";
import FieldLabel from "../../../../widgets/formInputFields/labels";
import CustomButton from "../../../../widgets/formInputFields/buttons";
import FieldErrorMessage from "../../../../widgets/formInputFields/errorMessage";
import { postData } from "../../../../adapters/hostelservices";
import sweetSuccessAlert from "../../../../widgets/alert/sweetAlert";
import { useParams } from "react-router-dom";
import WaveBottom from "../../../../assets/images/background/bg-modal.svg";
import { useSelector } from "react-redux";

const MakePaymentModal = ({
  onHide,
  modalShow,
  refreshToggle,
  toggleModalShow,
}: any) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState({
    message: "",
    alertBoxColor: "",
  });

  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError(t("hmd.amountMustNumber"))
      .required(t("hmd.amountReq"))
      .positive(t("hmd.amountGreater0")),
    paidBy: Yup.string().required(t("hmd.paidByReq")),
    notes: Yup.string()
      .required(t("hmd.apymentNodeReq"))
      .max(255, t("hmd.notesCannotChar")),
  });

  const initialValues = {
    amount: "",
    paidBy: "",
    notes: "",
  };

  const handleFormSubmit = useCallback((values: any, action: any) => {
    action.setSubmitting(true);
    postData(`/${currentInstitute}/hostel/${id}/admin/offlinePayment`, values)
      .then((res: any) => {
        if (res.status === 201) {
          toggleModalShow(false);
          action.setSubmitting(false);
          refreshToggle();
          sweetSuccessAlert({ text: t("hmd.paymentAddedSuccess") });
          action.resetForm();
        }
      })
      .catch((err: any) => {
        console.error(err);
        action.setSubmitting(false);
        if (err.response?.status === 400) {
          setShowAlert(true);
          setAlertMsg({
            message: `${err.response.data.message}. Please try again later`,
            alertBoxColor: "danger",
          });
        } else {
          setShowAlert(true);
          setAlertMsg({
            message: "Failed to reject request. Please try again.",
            alertBoxColor: "danger",
          });
        }
      });
  }, []);

  return (
    <Modal
      centered
      onHide={onHide}
      show={modalShow}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-design-wrapper"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("hmd.addPayment")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TimerAlertBox
          alertMsg={alertMsg.message}
          className="mt-3"
          variant={alertMsg.alertBoxColor}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, action) => {
            handleFormSubmit(values, action);
          }}
        >
          {({ isSubmitting, resetForm, errors, touched }) => (
            <Form>
              <div className="mb-3">
                <FieldLabel
                  htmlFor="amount"
                  labelText={t("hmd.amount")}
                  required
                  star="*"
                />
                <Field
                  type="number"
                  name="amount"
                  className="form-control"
                  placeholder={t("hmd.enterAmount")}
                />
                <FieldErrorMessage
                  errors={errors.amount}
                  touched={touched.amount}
                />
              </div>
              <div className="mb-3">
                <FieldLabel
                  htmlFor="paidBy"
                  labelText={t("hmd.paidBy")}
                  required
                  star="*"
                />
                <div>
                  <span className="me-2">
                    <Field type="radio" name="paidBy" value="cash" />{" "}
                    {t("hmd.cash")}
                  </span>
                  <span className="me-2">
                    <Field type="radio" name="paidBy" value="cheque" />{" "}
                    {t("hmd.cheque")}
                  </span>
                  <span>
                    <Field type="radio" name="paidBy" value="dd" />{" "}
                    {t("hmd.demandDraft")}
                    (DD)
                  </span>
                </div>
                <FieldErrorMessage
                  errors={errors.paidBy}
                  touched={touched.paidBy}
                />
              </div>
              <div className="mb-3">
                <FieldLabel
                  htmlFor="notes"
                  labelText={t("hmd.paymentNotes")}
                  required
                  star="*"
                />
                <Field
                  as="textarea"
                  name="notes"
                  className="form-control"
                  placeholder={t("calendar.typeHere")}
                />
                <FieldErrorMessage
                  errors={errors.notes}
                  touched={touched.notes}
                />
              </div>
              <div className="modal-buttons">
                <CustomButton
                  type="submit"
                  variant="primary"
                  isSubmitting={isSubmitting}
                  // onClick={handleOnClik}
                  btnText={t("common.submit")}
                />{" "}
                <CustomButton
                  type="reset"
                  btnText={t("common.reset")}
                  onClick={() => {
                    resetForm();
                    setShowAlert(false);
                  }}
                  variant="outline-secondary"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <img src={WaveBottom} alt="WaveBottom" className="wavebg" />
    </Modal>
  );
};

export default MakePaymentModal;
