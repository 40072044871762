import { useState, useCallback } from "react";
import { putData as coreApiCall } from "../../adapters/coreservices";
import { putData as microApiCall } from "../../adapters/microservices";
import { putData as hostelApiCall } from "../../adapters/hostelservices";
import { handleGlobalError } from "../../globals/GlobalErrorFunction";

interface FetchState<T> {
  apiData: T | null;
  loading: boolean;
  success: boolean;
  error: string | null;
  setError: any;
  apiStatus: string;
  showErrorAlert?: boolean;
  setShowErrorAlert?: any;
}

const useApiPutMethodHook = <T,>(
  URL: string,
  options?: RequestInit,
  service?: string
): FetchState<T> & { putData: (body: any) => Promise<void> } => {
  let getErrorObj = {};
  const [apiData, setApiData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [apiStatus, setApiStatus] = useState<string>("");
  const [error, setError] = useState<any>({
    message: "",
    alertBoxColor: "",
  });

  const putData = useCallback(
    async (body: any) => {
      setLoading(true);
      setError(null);
      setSuccess(false);
      setShowErrorAlert(false);
      setApiStatus("started");
      try {
        let res;
        // Use the appropriate API call based on the service
        if (service === "core") {
          res = await coreApiCall(URL, body);
        } else if (service === "hostel") {
          res = await hostelApiCall(URL, body);
        } else {
          res = await microApiCall(URL, body);
        }

        // Handle the response based on the expected result
        if (res.data !== "" && res.status === 200) {
          setApiData(res.data);
          setApiStatus("finished");
          setSuccess(true);
        } else {
          setError("Error: Data not received or status not 200");
        }
      } catch (error: any) {
        // Handle errors based on the response status
        if (error?.response?.status > 0) {
          getErrorObj = handleGlobalError(
            error.response.data.message,
            error.response.status
          );
        } else {
          getErrorObj = handleGlobalError("", 0);
        }
        setError(getErrorObj);
        setApiStatus("finished");
        setSuccess(false);
        setShowErrorAlert(true);
      } finally {
        setLoading(false);
        setApiStatus("finished");
      }
    },
    [URL, options, service]
  );

  return {
    apiData,
    loading,
    error,
    setError,
    success,
    apiStatus,
    putData,
    showErrorAlert,
    setShowErrorAlert,
  };
};

export default useApiPutMethodHook;
