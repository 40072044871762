import View from "./view";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pagination } from "../../../../utils/pagination";
import { getData } from "../../../../adapters/hostelservices";

type Props = {};

const UsersInvoiceDeatil = (props: Props) => {
  const dummyData = {
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  };
  const [hostelId, setHostelId] = useState(0);
  const [apiStatus, setApiStatus] = useState("");
  const { allocationStatus, paymentStatus } = useParams();
  const [allHostelData, setAllHostelData] = useState(dummyData);
  const [allHostelUsers, setAllHostelUsers] = useState(dummyData);
  const [filterUpdate, setFilterUpdate] = useState<any>({
    fullName: "",
    hostelId: "",
    invoiceNumber: "",
    roomNumber: "",
    email: "",
    transactionNumber: "",
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
    roomStatus: allocationStatus || null,
    billStatus: paymentStatus || null,
  });
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const currentUserRoleId = useSelector(
    (state: any) => state.globalFilters.currentUserRole.id
  );

  useEffect(() => {
    if (currentUserRoleId > 0) {
      getData(
        `/${currentInstitute}/hostels/${currentUserRoleId}/all-hostel`,
        filterUpdate
      )
        .then((result: any) => {
          if (result.data.items !== "" && result.status === 200) {
            setAllHostelData(result.data);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [currentUserRoleId]);

  useEffect(() => {
    setApiStatus("started");
    if (!currentInstitute) return;
    // Filter out empty values from filterUpdate
    const cleanedFilters = Object.entries(filterUpdate).reduce(
      (acc, [key, value]) => {
        if (value !== "" && value !== null && value !== undefined) {
          acc[key] = value;
        }
        return acc;
      },
      {} as any
    );
    // Fetch room allocations with cleaned filters and update state
    getData(`/${currentInstitute}/hostel/${currentUserRoleId}/users-invoice`, {
      ...cleanedFilters,
      ...(hostelId && { hostelId }),
    })
      .then((result: any) => {
        if (result.data && result.status === 200) {
          setAllHostelUsers(result.data);
          setApiStatus("finished");
        }
      })
      .catch((err: any) => {
        setApiStatus("finished");
        console.error("Error fetching room-allocations:", err);
      });
  }, [currentInstitute, currentUserRole?.id, filterUpdate, hostelId]); 

  const updateInputFilters = (inputvalues: any) => {
    if (inputvalues.reset) {
      setFilterUpdate({
        pageNumber: 0,
        pageSize: 10,
        roomStatus: allocationStatus || null,
        billStatus: paymentStatus || null,
      });
      return;
    }
    setFilterUpdate((prevFilters: any) => ({
      ...prevFilters,
      ...inputvalues,
      pageNumber: 0, // Reset to first page on filter update
    }));
  };

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate({ ...filterUpdate, pageNumber: pageRequest });
  };

  return (
    <View
      loading={apiStatus}
      hostelId={hostelId}
      setHostelId={setHostelId}
      filterUpdate={filterUpdate}
      newPageRequest={newPageRequest}
      allHostelData={allHostelData?.items}
      allHostelUsers={allHostelUsers?.items}
      updateInputFilters={updateInputFilters}
      totalPages={allHostelUsers.pager.totalPages}
    />
  );
};

export default UsersInvoiceDeatil;
