import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import FilterButtonWrapper from "../../../../widgets/filterButtonWrapper";
import { Label } from "recharts";
import { useTranslation } from "react-i18next";

interface FilterProps {
  updateInputFilters: (filters: any) => void;
  allHostelData: any;
}

const Filter: React.FC<FilterProps> = ({
  updateInputFilters,
  allHostelData,
}) => {
    const { t } = useTranslation();
  
  const navigate = useNavigate();
  const [timeoutId, setTimeoutId] = useState<any>(null);

  const initialValues = {
    hostelId: "",
    fullName: "",
    roomNumber: "",
    bedNumber: "",
    invoiceNumber: "",
    transactionNumber: "",
    billStatus: "pending",
    roomStatus: "active",
    email: "",
    startDate: "",
    endDate: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (timeoutId) clearTimeout(timeoutId);
      updateInputFilters(values);
    },
    onReset: () => {
      if (timeoutId) clearTimeout(timeoutId);
      formik.setValues(initialValues);
      updateInputFilters({ reset: true });
    },
  });

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    formik.setFieldValue(name, value); // Update Formik state

    if (timeoutId) clearTimeout(timeoutId); // Clear previous debounce

    const newTimeoutId = setTimeout(() => {
      updateInputFilters({ [name]: value }); // Send updated filter values
    }, 500); // Debounce input updates

    setTimeoutId(newTimeoutId);
  };

  return (
    <div className="filter-wrapper mt-2 me gap-3 umanagement-filter">
      <FilterButtonWrapper>
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <Row className="g-2">

            <Col md="auto">
            <label htmlFor="invoiceNumber">{t('hmd.invoiceNumber')}</label>
              <input
                className="form-control"
                id="invoiceNumber"
                name="invoiceNumber"
                type="text"
                placeholder={t('hmd.invoiceNumber')}
                onChange={handleFilterChange}
                value={formik.values.invoiceNumber}
              />
            </Col>
            
            <Col md="auto">
              <label htmlFor="roomNumber">{t('hmd.roomNumber')}</label>
              <input
                className="form-control"
                id="roomNumber"
                name="roomNumber"
                type="text"
                placeholder={t('hmd.roomNumber')}
                onChange={handleFilterChange}
                value={formik.values.roomNumber}
              />
            </Col>

            <Col md="auto">
              <label htmlFor="transactionNumber">{t('hmd.transactionNumber')}</label>
              <input
                className="form-control"
                id="transactionNumber"
                name="transactionNumber"
                type="text"
                placeholder={t('hmd.transactionNumber')}
                onChange={handleFilterChange}
                value={formik.values.transactionNumber}
              />
            </Col>

            <Col md="auto">
              <label htmlFor="billStatus">{t('hmd.invoiceStatus')}</label>
              <select
                className="form-select"
                name="billStatus"
                onChange={handleFilterChange}
                value={formik.values.billStatus}
              >
                <option value="">{t('common.all')}</option>
                <option value="pending">{t('hmd.pending')}</option>
                <option value="paid">{t('hmd.paid')}</option>
              </select>
            </Col>

            <Col md="auto">
              <label htmlFor="roomStatus">{t('hmd.allocationStatus')}</label>
              <select
                className="form-select"
                name="roomStatus"
                onChange={handleFilterChange}
                value={formik.values.roomStatus}
              >
               <option value="">{t('common.all')}</option>
                <option value="active">{t('hmd.active')}</option>
                <option value="completed">{t('common.completed')}</option>
              </select>
            </Col>

            <Col md="auto">
              <label htmlFor="startDate">{t('common.startDate')}</label>
              <input
                className="form-control"
                id="startDate"
                name="startDate"
                type="date"
                placeholder={t('common.startDate')}
                onChange={handleFilterChange}
                value={formik.values.startDate}
              />
            </Col>

            <Col md="auto">
              <label htmlFor="endDate">{t('common.endDate')}</label>
              <input
                className="form-control"
                id="End Date"
                name="endDate"
                type="date"
                placeholder={t('common.endDate')}
                onChange={handleFilterChange}
                value={formik.values.endDate}
              />
            </Col>

            <Col md="auto">
              <div className="d-flex align-items-end h-100">
                <Button variant="primary" type="submit">
                  {t("common.filter")}
                </Button>
                <Button variant="outline-secondary" type="reset" className="ms-2">
                {t("common.reset")}
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </FilterButtonWrapper>

      {/* <div className="site-button-group">
        <Button variant="primary" onClick={() => navigate("paymentmethod")}>
          Payment Methods
        </Button>
      </div> */}
    </div>
  );
};

export default Filter;
