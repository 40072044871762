import { useTranslation } from 'react-i18next';
import institutemanagement from '../../../assets/images/institute-management.png';
import usermanagement from '../../../assets/images/user-management.png';
import manageprogram from '../../../assets/images/program-management.png';
import programenrollment from '../../../assets/images/program-enrollment.png';
import copomanagement from '../../../assets/images/copo-management.png';
import timetablemanagement from '../../../assets/images/timetable-management.png';
import hostelmanagement from '../../../assets/images/hostel-management.png';
import lockmanagement from '../../../assets/images/lock.svg';
export const AdminRawData = () => {
  const {t} = useTranslation();

  return[
  [    
    {
      title: t('siteAdmin.userMgt'),
      image: usermanagement,
      link: "usermanagement",
      classname: "user-management",
      boxclassname: "",
      component: 'user',
      enabled: true,
    },
    {
      title: t('siteAdmin.prgMgt'),
      image: manageprogram,
      link: "manageprogram",
      classname: "manage-program",
      boxclassname: "middle",
      component: 'program',
      enabled: true,
    },
    {
      title: t('siteAdmin.prgEnroll'),
      image: programenrollment,
      link: "programenrollment",
      classname: "program-enrollment",
      boxclassname: "",
      component: 'enrolment',
      enabled: true,
    },
  ],
  [        
    {
      title: t('siteAdmin.trp'),
      image: timetablemanagement,
      link: "/timetable",
      classname: "timetable-management",
      boxclassname: "",
      component: 'timetable',
      enabled: true,
    },
    {
      title: t('siteAdmin.naacFramework'),
      image: copomanagement,
      link: "copomanagement",
      classname: "copo-management",
      boxclassname: "middle",  
      component: 'copo',
      enabled: true,
    },
    {
      title: t('siteAdmin.blGateBridge'),
      image: institutemanagement,
      link: "institute",
      classname: "institute-management",
      boxclassname: "",   
      component: 'institute',
      enabled: true,
    },
  ],
  [    
    {
      title: t('siteAdmin.hostelMng'),
      image: hostelmanagement,
      link: "hostelmanagement",
      classname: "",
      boxclassname: "",   
      component: 'hostel',
      enabled: true,
    },
    {
      title: "",
      image: lockmanagement,
      link: "",
      classname: "admin-lock",
      boxclassname: "middle",
      component: 'program',
      enabled: true,
    },
    {
      title: "",
      image: lockmanagement,
      link: "",
      classname: "admin-lock",
      boxclassname: "",
      component: 'enrolment',
      enabled: true,
    },
  ]
]
}
