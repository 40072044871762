import React from "react";
import { useSelector } from "react-redux";
import { FaDownload } from "react-icons/fa6";
import { Link } from "react-router-dom";
import invoiceDownload from "../../../../globals/pdfDownload";
import ReusableReactTable from "../../../../components/reusableReactTable";
import { capitalizeFirstWords } from "../../../../globals/titleCapitalize/capitalizeFirstWords";
import { useTranslation } from "react-i18next";
import { formatDateToDDMMYYYY } from "../../../../lib/timestampConverter";
import i18n from "../../../../languages";

const UserBillTable = ({ apiStatus, studentBillData }: any) => {

  const { t } = useTranslation();
  // Define the data
  const data = React.useMemo(() => studentBillData, [studentBillData]);
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  // Define the columns
  const columns = React.useMemo(
    () => [
      {
        Header: t('hmd.invoiceNumber'),
        accessor: (row: any) => {
          return (
            <>
              <Link
                className="action-icons"
                to="#"
                onClick={() =>
                  invoiceDownload(row.userBillId, currentInstitute)
                }
              >
                {row.invoiceNumber} <FaDownload />
              </Link>
            </>
          );
        },
      },
      {
        Header: t('hmd.fullName'),
        accessor: (row: any) => {
          return capitalizeFirstWords(row.fullName);
        },
      },
      {
        Header: t('common.email'),
        accessor: "email",
      },
      {
        Header: t('hmd.roomNumber'),
        accessor: (row) => <div className="action-icons">{row.roomNumber}</div>,
      },
      {
        Header: t('hmd.bedNumber'),
        accessor: (row) => <div className="action-icons">{row.bedNumber}</div>,
      },
      {
        Header: `${t('common.startDate')} (DD-MM-YYYY)`,
        accessor: (row) => (
          <div className="text-nowrap">
            {formatDateToDDMMYYYY(row.validFrom)}</div>
        ),
      },
      {
        Header: `${t('common.endDate')} (DD-MM-YYYY)`,
        accessor: (row) => (
          <div className="text-nowrap">
            {formatDateToDDMMYYYY(row.validTo)}</div>
        ),
      },
      {
        Header: `${t('hmd.dueDate')} (DD-MM-YYYY)`,
        accessor: (row) => (
          <div className="text-nowrap">
            {formatDateToDDMMYYYY(row.dueDate)}</div>
        ),
      },
      {
        Header: `${t('hmd.amount')} (${t('hmd.inr')})`,
        accessor: (row) => (
          <div className="action-icons">{`${row.amount} Rs`}</div>
        ),
      },

      {
        Header: `${t('hmd.paidAmount')}  (${t('hmd.inr')})`,
        accessor: (row) => (
          <div className="action-icons">{`${row.totalPaidAmount} Rs`}</div>
        ),
      },
      {
        Header: `${t('hmd.amountDue')} (${t('hmd.inr')})`,
        accessor: (row) => (
          <div className="action-icons">{`${row.amountDue} Rs`}</div>
        ),
      },

      {
        Header: t('hmd.invoiceStatus'),
        accessor: (row) => {
          return (
            <div
              className={`statusBadges ${row.billstatus === "paid"
                ? "active"
                : row.billstatus === "pending"
                  ? "pending"
                  : ""
                }`}
            >
              {capitalizeFirstWords(row.billstatus)}
            </div>
          );
        },
      },
      {
        Header: t('hmd.allocationStatus'),
        accessor: (row) => {
          return (
            <div
              className={`statusBadges ${row.roomStatus === "active"
                ? "active"
                : row.roomStatus === "pending"
                  ? "pending"
                  : row.roomStatus === "completed"
                    ? "completed"
                    : ""
                }`}
            >
              {capitalizeFirstWords(row.roomStatus)}
            </div>
          );
        },
      },
      {
        Header: t('hmd.transactionNumber'),
        accessor: (row: any) => {
          if (!row.payments || row.payments.length === 0) {
            return <div className="text-center">N/A</div>;
          }
          return row.payments.map((transactionData: any) => (
            <div key={transactionData?.transaction?.transactionNumber}>
              {transactionData?.transaction?.transactionNumber ||
                <div className="text-center">N/A</div>}
            </div>
          ));
        },
      },
      // {
      //   Header: t('hmd.transaction'),
      //   accessor: (row: any) => {
      //     if (!row.payments || row.payments.length === 0) {
      //       return "-";
      //     }
      //     return row.payments.map((transactionData: any) => (
      //       <div key={transactionData?.transaction?.id}>
      //         {transactionData?.transaction?.id || "-"}
      //       </div>
      //     ));
      //   },
      // },

      // {
      //   Header: t('hmd.transactionStatus'),
      //   accessor: (row: any) => {
      //     return row.payments?.map((transactionData: any) => capitalizeFirstWords(transactionData?.transaction?.transactionStatusType)).join(", ") || "-";
      //   },
      // },
      // {
      //   Header: t('hmd.paymentMode'),
      //   accessor: (row: any) => {
      //     return row.payments?.map((transactionData: any) => transactionData?.paymentMethod?.name).join(", ") || "-";
      //   },
      // },
      // {
      //   Header: t("hmd.transactionStatus"),
      //   accessor: (row: any) => {
      //     return Array.isArray(row.payments) && row.payments.length > 0
      //       ? row.payments.map((transactionData: any, index: number) => (
      //           <div key={index} className="text-nowrap text-center">
      //             {`${transactionData?.transaction?.id || "-"} (${capitalizeFirstWords(transactionData?.transaction?.transactionStatusType || "-")}) ${transactionData?.paymentMethod?.name || "-"}`}
      //           </div>
      //         ))
      //       : <div className="text-center">N/A</div>;
      //   },
      // },
      {
        Header: t('common.action'),
        accessor: "action",
        Cell: ({ row }: any) => (
          <>
            {/* <Link
              className="action-icons me-2"
              to={`userpaymentdetails/${row?.original?.id}`}
            >
              Waive Off
            </Link> */}
            <Link
              className="action-icons"
              to={`userpaymentdetails/${row?.original?.userBillId}`}
            >
              {row.original.billstatus === "paid" ? t('hmd.viewInvoice') : t('hmd.pay')}
            </Link>
          </>
        ),
      },
    ],
    [i18n.language]
  );
  return (
    <React.Fragment>
      <div className="table-responsive admin-table-wrapper mt-3">
        <ReusableReactTable data={data} columns={columns} loading={apiStatus} />
      </div>
    </React.Fragment>
  );
};

export default UserBillTable;
