import React from "react";
import TabsList from "./../../tabs";
// import HeaderTabs from "../../../../headerTabs";
import { Container } from "react-bootstrap";
import PageTitle from "../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../widgets/breadcrumb";
import './../../style.scss';
import "./../../mobileStyle.scss";
import { BackgroundWaveBottomLeft, BackgroundWaveRight } from "../../../../../widgets/backgroundElements";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {};

const Browser = (props: Props) => {
  const {t} = useTranslation();
  const { id, name, cid, cname } = useParams()

  return (
    <React.Fragment>      
      <div className="contentarea-wrapper mb-5">
        <BreadcrumbComponent
          routes={[
            { name: t("common.home"), path: "/home" },
            { name: t('common.siteAdmin'), path: "/siteadmin" },
            { name: t('naac.coPoMgmt'), path: "/siteadmin/copoManagement" },
            { name: name, path: `/siteadmin/copoManagement/copoCourse/${id}/${name}`},
            { name: cname, path: "" },
          ]}
        />
        <Container fluid className="mt-3">
          <PageTitle pageTitle={`${t('naac.configCoPoCourse')}: <span>${cname}</span>`} gobacklink={`/siteadmin/copoManagement/copoCourse/${id}/${name}`} />
          <TabsList />
        </Container>
      </div>
      <BackgroundWaveBottomLeft/>
      <BackgroundWaveRight/>
    </React.Fragment>
  );
};

export default Browser;
