import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  onHide: any;
  modalShow: any;
  apiStatus: any;
  refreshToggle: any;
  allHostelData: any;
  toggleModalShow: any;
  studentBillData: any;
  roomBedAllocations: any;
  updateInputFilters : any;
  paymentMethodsData : any;
};

const View = (props: Props) => {
  const commonProps = {
    onHide: props.onHide,
    apiStatus: props.apiStatus,
    modalShow: props.modalShow,
    allHostelData: props.allHostelData,
    refreshToggle: props.refreshToggle,    
    studentBillData: props.studentBillData,
    toggleModalShow: props.toggleModalShow,
    updateInputFilters : props.updateInputFilters,
    roomBedAllocations : props.roomBedAllocations,
    paymentMethodsData : props.paymentMethodsData
  };
 
  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;
