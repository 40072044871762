
import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  loading: any;
  hostelId: any;
  totalPages: any;
  setHostelId: any;
  filterUpdate: any;
  allHostelData: any;
  allHostelUsers: any;
  newPageRequest: any;
  updateInputFilters:any;
};

const View = (props: Props) => {
  const commonProps = {
    loading: props.loading,
    hostelId: props.hostelId,
    totalPages: props.totalPages,
    setHostelId: props.setHostelId,
    filterUpdate: props.filterUpdate,
    allHostelData: props.allHostelData,
    allHostelUsers: props.allHostelUsers,
    newPageRequest: props.newPageRequest,
    updateInputFilters: props.updateInputFilters,
  };
  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;

