import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../widgets/breadcrumb";
import { BackgroundWaveBottomLeft, BackgroundWaveRight } from "../../../../../widgets/backgroundElements";

type Props = {
  commonProps: {
    adminRawData: any[];
    renderComponent: any;
  };
};

const Browser = (props: Props) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <div className="contentarea-wrapper mb-5">
        <BreadcrumbComponent routes={[{ name: t("common.home"), path: "/home" },{ name: t('common.siteAdmin'), path: "" }]} />
        <Container fluid className="mt-3">
          <PageTitle pageTitle={t('common.siteAdmin')} gobacklink="/home" />
        </Container>
        {props.commonProps.adminRawData.map((item: any, index: number) => (
          <Container fluid
            key={index}
            className={`administration-box row${index + 1}`}
          >
            {item.map((item: any, index: number) =>
              props.commonProps.renderComponent(item, index)
            )}
          </Container>
        ))}
      </div>
      <BackgroundWaveRight/>
      <BackgroundWaveBottomLeft/>
    </React.Fragment>
  );
};

export default Browser;
