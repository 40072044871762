import AuthRoute from "./authRoute";
import React, { Suspense, useContext } from "react";
import HomePageRoute from "./homeRoute";
import ProfileRoute from "./profileRoute";
import PageNotFound from "../../pages/404";
import HeaderTabsRoute from "./headerTabsRoute";
import MinorCourse from "../../pages/minorCourses";
import { ProtectedRoutes } from "./ProtectedRoutes";
import CoPoManagementRoute from "./siteAdminRoute/copoManagement";
import UserManagementRoute from "./siteAdminRoute/userManagementRoute";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import HostelManagementRoute from "./siteAdminRoute/hostelManagementRoute";
import TimetableManagementRoute from "./siteAdminRoute/timetableManagement";
import ProgramManagementRoute from "./siteAdminRoute/programManagementRoute";
import ProgramEnrollmentRoute from "./siteAdminRoute/programEnrollmentRoute";
import CalenderManagementRoute from "./siteAdminRoute/calenderManagementRoute";
import RouterLadyLoader from "../../globals/globalLazyLoader/routerLadyLoader";
import MailTempelateConfiguration from "../../pages/mailTempelateConfiguration";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/header/mobileHeader";
import Header from "../../components/header";
import UserContext from "../context/user/user";
import MobileFooter from "../../components/footer/mobileFooter";
import Footer from "../../components/footer";
import Landingpage from "../../pages/landingPage/index";
import SiteAdminHome from "../../pages/siteAdminstration/siteAdmin";
import StudentHostelManagementRoutes from "./siteAdminRoute/studentHostelManagementRoutes";

// import { Navigate, Outlet } from 'react-router-dom';

const ProgramOverview = React.lazy(() => import("../../pages/programOverview"));
const InstituteManagement = React.lazy(() => import("../../pages/siteAdminstration/institute"))

export default function NewCustomRoutes() {
  const userCtx = useContext(UserContext);
    const isLoggedIn = userCtx.isLoggedIn;
  return (
    <BrowserRouter>
     {isMobile ? ( isLoggedIn && <MobileHeader/> ) 
              : ( isLoggedIn &&  <Header /> )}
      <Suspense fallback={<RouterLadyLoader />}>
        <Routes>
          {/* <Route element={<><Outlet /><MitGlobalAlert /></>}>
           * create a new component for global imports
           */}

          {/* Auth Components Routes Define */}
          {AuthRoute()}

          {/* Home page Components Routes Define */}
          {HomePageRoute()}

          <Route element={<ProtectedRoutes />}>
            {/* Header Tabs Components Routes Define */}
            {HeaderTabsRoute()}

            {/* Profile Components Routes Define */}
            {ProfileRoute()}

            {/* Site Administration Components Routes Define */}
            {CalenderManagementRoute()}
            {TimetableManagementRoute()}
            
            {StudentHostelManagementRoutes()}
              
            <Route path="/siteadmin" key="siteadmin" element={<Outlet />} >
              <Route index element={<SiteAdminHome />} />
              <Route path="institute" element={<InstituteManagement />} />
              {UserManagementRoute()}
              {ProgramManagementRoute()}
              {ProgramEnrollmentRoute()}
              {CoPoManagementRoute()}
              {HostelManagementRoute()}
            </Route>,

            <Route path="/home" element={< Landingpage/>} />

            <Route path="/programoverview" element={<ProgramOverview />} />
            <Route
              path="/mailtempelateconfiguration"
              element={<MailTempelateConfiguration />}
            />
            <Route path="/minorcourse" element={<MinorCourse />} />
            {/* <Route
              path="/userwaitlist/:programid/:name/:courseid/:coursename"
              element={<UserWaitlist />}
            /> */}
            <Route path="*" element={<PageNotFound />} />
          </Route>
          {/* </Route> */}
        </Routes>
        {isMobile ? (
          isLoggedIn && <MobileFooter /> 
        ) : (
          isLoggedIn && <Footer /> 
        )}
      </Suspense>
    </BrowserRouter>
  );
}
