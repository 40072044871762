import Filter from "../../filter";
import { Container } from "react-bootstrap";
import BookingRequestTable from "../../table";
import AddBookingRequestModal from "../../form";
import PageTitle from "../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import { useTranslation } from "react-i18next";
import UserBillTable from "../../table";

type Props = {
  commonProps: {
    modalShow: any;
    apiStatus: any;
    refreshToggle: any;
    toggleModalShow: any;
    onHide: any;
    updateInputFilters : any;
    studentBillData: any;
    roomBedAllocations: any;
    paymentMethodsData : any
  };
};

const Mobile = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <BreadcrumbComponent
        routes={[
          { name: t("common.home"), path: "/home" },
          { name: t('hmd.myHostel'), path: "/myhostel" },
          { name: t('hmd.invoices&Payments'), path: "" },
        ]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <div className="contentarea-wrapper mt-3 mb-5">
        <Container fluid>
          <PageTitle
            pageTitle={t('hmd.invoices&Payments')}
            gobacklink="/myhostel"
          />
          <Filter
            toggleModalShow={props.commonProps.toggleModalShow}
            roomBedAllocations ={props.commonProps.roomBedAllocations}
            paymentMethodsData ={props.commonProps.paymentMethodsData}
            updateInputFilters= {props.commonProps.updateInputFilters}
          />
           
           <UserBillTable
            apiStatus={props.commonProps.apiStatus}
            studentBillData = {props.commonProps.studentBillData}
          />
          {/* <AddBookingRequestModal
            onHide={props.commonProps.onHide}
            modalShow={props.commonProps.modalShow}
            hostelData={props.commonProps.hostelData}
            setHostelId={props.commonProps.setHostelId}
            facilityData={props.commonProps.facilityData}
            bookingReqObj={props.commonProps.bookingReqObj}
            updateAddRefresh={props.commonProps.refreshToggle}
            toggleModalShow={props.commonProps.toggleModalShow}
            preferencesData={props.commonProps.preferencesData}
          /> */}

        </Container>
        </div>
        </div>
    </>
  );
};

export default Mobile;
